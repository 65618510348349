import "./utils/i18n";
import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {store} from "./store/store";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import Theme from "./utils/theme";
import {ThemeProvider} from "@mui/material/styles";
import {HashRouter} from "react-router-dom";
import {Backdrop} from "@mui/material";
import {msalInstance} from "./utils/msalProvider";
import {MsalProvider} from "@azure/msal-react";
import {Layout} from "containers/Layout";
import "./scss/style.scss";


const loading = ()=> <Backdrop open={true} />;
ReactDOM.render(
    <React.StrictMode>
      <Suspense fallback={loading()}>
        <HashRouter>
          <MsalProvider instance={msalInstance}>
            <ThemeProvider theme={Theme}>
              <Provider store={store}>
                <Layout/>
              </Provider>
            </ThemeProvider>
          </MsalProvider>
        </HashRouter>
      </Suspense>
    </React.StrictMode>
    , document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
