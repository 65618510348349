import {createSlice, PayloadAction, createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "store/store";
import {fieldsInfo} from "./utils";
import {post, get} from "../../utils/api";
import {displayToastError, displayToastSuccess} from "features/ErrorBoundary/errorBoundarySlice";


export const postAppartment = createAsyncThunk(
    "ApartmentForm/postAppartment",
    async (appartment:any, {rejectWithValue, dispatch}) => {
      const response = await post("home4/appartments", appartment);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("POST.APPARTMENT.FAILED"));
        return rejectWithValue("Cannot POST  appartment");
      } else {
        dispatch(displayToastSuccess("POST.APPARTMENT.SUCCESS"));
        return response;
      }
    },
);


export const getAppartment = createAsyncThunk(
    "ApartmentForm/getAppartment",
    async (appartment_id:any, {rejectWithValue, dispatch}) => {
      const response = await get("home4/appartments/"+ appartment_id);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("GET.APPARTMENT.FAILED"));
        return rejectWithValue("Cannot GET  appartment");
      } else {
        dispatch(displayToastSuccess("GET.APPARTMENT.SUCCESS"));
        return response;
      }
    },
);

export const editAppartment = createAsyncThunk(
    "ApartmentForm/editAppartment",
    async ({appartment_id, appartmentEditData}:{appartment_id:string, appartmentEditData:any}, {rejectWithValue, dispatch}) => {
      const response = await post("home4/appartments/" + appartment_id, appartmentEditData);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("EDIT.APPARTMENT.FAILED"));
        return rejectWithValue("Cannot EDIT appartment");
      } else {
        dispatch(displayToastSuccess("EDIT.APPARTMENT.SUCCESS"));
        return response;
      }
    },
);


export interface apartmentFormState {
  residenceFields: {
    [key:string]:string | number | boolean
  },
  roomData:{
    roomFields:{
      room_id?: any;
      name:string
      equipment:string[]
      room_type:string
      deleted?:boolean
    }[]
  },
  sensorData:{
    sensorFields:{
      id: string;
      clientid: string;
      attribute:string;
      value:string;
      timestamp:string;
      roomName:string;
      roomType:string;
    }[]
  }
  loading: {
    postAppartment:string,
    getAppartment:string,
    editAppartment:string
  },
  error: {[key:string]:boolean},
}

const initialState: apartmentFormState = {
  roomData: {
    roomFields: [
      {
        name: "",
        equipment: [""],
        room_type: "",
        deleted: false,
      },
    ],
  },
  sensorData: {
    sensorFields: [
      {
        id: "",
        clientid: "",
        attribute: "",
        value: "",
        timestamp: "",
        roomName: "",
        roomType: "",
      },
    ],
  },
  residenceFields: Object
      .keys(fieldsInfo)
      .filter((e)=>fieldsInfo[e].page==="residence")
      .reduce((a, e)=>({...a, [e]: fieldsInfo[e].initialValue}), {}),

  loading: {
    postAppartment: "idle",
    getAppartment: "idle",
    editAppartment: "idle",
  },
  error: Object
      .keys(fieldsInfo)
      .filter((e)=>fieldsInfo[e].page==="residence")
      .reduce((a, e)=>({...a, [e]: false}), {}),
};


export const apartmentFormSlice = createSlice({
  name: "ApartmentForm",
  initialState,
  reducers: {
    clearAppartmentFormFields: (state)=>{
      state.roomData=initialState.roomData;
      state.residenceFields=initialState.residenceFields;
    },
    changeResidenceFieldValue: (state, action: PayloadAction<{field:string, value:any}>) => {
      state.residenceFields[action.payload.field] = action.payload.value;
      state.error[action.payload.field]= fieldsInfo[action.payload.field].validate(action.payload.value);
    },
    changeRoomNameValue: (state, action: PayloadAction<{roomIndex:number, value:string}>)=>{
      state.roomData.roomFields[action.payload.roomIndex].name=action.payload.value;
    },
    changeRoomTypeValue: (state, action: PayloadAction<{roomIndex:number, value:string}>)=>{
      state.roomData.roomFields[action.payload.roomIndex].room_type=action.payload.value;
    },
    changeEquipmentValue: (state, action: PayloadAction<{roomIndex:number, equipmentIndex:number, value:string}>)=>{
      state.roomData.roomFields[action.payload.roomIndex].equipment[action.payload.equipmentIndex]=action.payload.value;
    },
    addRoom: (state, action: PayloadAction)=>{
      state.roomData.roomFields.push(
          {
            name: "",
            equipment: [""],
            room_type: "",
          },
      );
    },
    addEquipmentToRoom: (state, action: PayloadAction<{roomIndex:number}>)=>{
      state.roomData.roomFields[action.payload.roomIndex].equipment.push("");
    },
    removeRoom: (state, action: PayloadAction<{roomIndex:number}>)=>{
      let roomToDelete={...state.roomData.roomFields[action.payload.roomIndex]};
      if (roomToDelete.room_id) {
        roomToDelete={...roomToDelete, deleted: true};
        state.roomData.roomFields[action.payload.roomIndex]=roomToDelete;
      } else {
        state.roomData.roomFields.splice(action.payload.roomIndex, 1);
      }
    },
    removeEquipment: (state, action: PayloadAction<{roomIndex:number, equipmentIndex:number}>)=>{
      state.roomData.roomFields[action.payload.roomIndex].equipment.splice(action.payload.equipmentIndex, 1);
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(postAppartment.pending, (state, action) => {
          if (state.loading.postAppartment === "idle") {
            state.loading.postAppartment = "pending";
          }
        })
        .addCase(postAppartment.fulfilled, (state, action) => {
          if (
            state.loading.postAppartment === "pending"
          ) {
            state.loading.postAppartment = "idle";
          }
        })
        .addCase(postAppartment.rejected, (state, action) => {
          if (
            state.loading.postAppartment === "pending"
          ) {
            state.loading.postAppartment = "idle";
          }
        })
        .addCase(getAppartment.pending, (state, action) => {
          if (state.loading.getAppartment === "idle") {
            state.loading.getAppartment = "pending";
          }
        })
        .addCase(getAppartment.fulfilled, (state, action) => {
          if (
            state.loading.getAppartment === "pending"
          ) {
            const {rooms, sensorData, ...appartmentFields}=action.payload.result;
            state.residenceFields=appartmentFields;
            state.roomData={roomFields: rooms};
            state.sensorData={sensorFields: sensorData};
            state.loading.getAppartment = "idle";
          }
        })
        .addCase(getAppartment.rejected, (state, action) => {
          if (
            state.loading.getAppartment === "pending"
          ) {
            state.loading.getAppartment = "idle";
          }
        })
        .addCase(editAppartment.pending, (state, action) => {
          if (state.loading.editAppartment === "idle") {
            state.loading.editAppartment = "pending";
          }
        })
        .addCase(editAppartment.fulfilled, (state, action) => {
          if (
            state.loading.editAppartment === "pending"
          ) {
            state.loading.editAppartment= "idle";
          }
        })
        .addCase(editAppartment.rejected, (state, action) => {
          if (
            state.loading.editAppartment === "pending"
          ) {
            state.loading.editAppartment = "idle";
          }
        });
  },
});

export const {
  changeResidenceFieldValue,
  changeRoomNameValue,
  changeEquipmentValue,
  changeRoomTypeValue,
  addRoom,
  addEquipmentToRoom,
  clearAppartmentFormFields,
  removeRoom,
  removeEquipment,
} = apartmentFormSlice.actions;

export const selectResidenceFields = (state: RootState) => state.apartmentForm.residenceFields;
export const selectRoomData = (state: RootState) => state.apartmentForm.roomData;
export const selectHubs = (state: RootState) => state.sensorForm.hubs;
export const selectErrorField= (state: RootState) => state.apartmentForm.error;
export const selectLoadingAppartmentForm= (state: RootState) => state.apartmentForm.loading;

export default apartmentFormSlice.reducer;
