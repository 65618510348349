import {
  combineReducers,
} from "redux";
import UserReducer from "features/user/userSlice";
import errorBoundaryReducer from "features/ErrorBoundary/errorBoundarySlice";
import apartmentFormReducer from "features/ApartmentForm/apartmentFormSlice";
import patientFormReducer from "features/PatientForm/patientFormSlice";
import sensorListReducer from "features/SensorList/SensorListSlice";
import appartmentListReducer from "features/AppartmentList/AppartmentListSlice";
import bookingListReducer from "features/BookingList/bookingListSlice";
import sensorFormReducer from "features/SensorForm/sensorFormSlice";

const rootReducer = combineReducers({
  userInformation: UserReducer,
  apartmentForm: apartmentFormReducer,
  patientForm: patientFormReducer,
  snackbar: errorBoundaryReducer,
  sensorList: sensorListReducer,
  appartmentList: appartmentListReducer,
  bookingList: bookingListReducer,
  sensorForm: sensorFormReducer,
});
export default rootReducer;
