import {createTheme} from "@mui/material/styles";

export default createTheme({


  palette: {
    primary: {
      main: "#63c2de",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9da5b1",
      contrastText: "#fff",
    },
    success: {
      main: "#56b472",
      contrastText: "#fff",
    },
    warning: {
      main: "#ffc107",
      contrastText: "#fff",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#23282c",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: "#23282c !important",
        },
      },
    },

    MuiStepIcon: {
      styleOverrides: {
        "root": {
          color: "#c8ced3",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          color: "#23282c !important",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            background: "transparent",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          "backgroundColor": "#fff !important",
        },
      },
    },
  },
  typography: {
    fontFamily: "Nunito",
    allVariants: {
      color: "#23282c",
    },
  },


  // brandbook: {
  //   body: "#23282c",
  //   blue: "#20a8d8",
  //   lightblue: "#8ad4ee",
  //   darkblue: "#167495",
  //   indigo: "#6610f2",
  //   purple: "#6f42c1",
  //   pink: "#e83e8c",
  //   danger: "#f86c6b",
  //   dangerhoover: "#f64846",
  //   dangerhooverborder: "#f63c3a",
  //   orange: "#f8cb00",
  //   yellow: "#ffc107",
  //   green: "#4dbd74",
  //   teal: "#20c997",
  //   cyan: "#17a2b8",
  //   white: "#fff",
  //   gray: "#73818f",
  //   disabled: "#73818f",
  //   graydark: "#2f353a",
  //   primary: "#20a8d8",
  //   secondary: "#c8ced3",
  //   success: "#4dbd74",
  //   info: "#63c2de",
  //   warning: "#ffc107",
  //   light: "#f0f3f5",
  //   dark: "#2f353a",
  //   btnlight: "#23282c",
  //   btnlightbackground: "#f0f3f5",
  //   btnlightbordercolor: "#f0f3f5",
  // },
});

