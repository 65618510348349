import {createSlice, PayloadAction, createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "store/store";
import {fieldsInfo} from "./utils";
import {post, get} from "../../utils/api";
import {formatDateForAvailability} from "utils/formatting";
import {availableAppartmentType, diagnosisType, insuranceCompaniesType, presenceType} from "./types";
import {displayToastError, displayToastSuccess} from "features/ErrorBoundary/errorBoundarySlice";


export const postBooking = createAsyncThunk(
    "patientForm/postBooking",
    async (booking:any, {dispatch, rejectWithValue}) => {
      const response = await post("home4/bookings/", booking);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("POST.BOOKING.FAILED"));
        return rejectWithValue("Cannot post booking");
      } else {
        dispatch(displayToastSuccess("POST.BOOKING.SUCCESS"));
        return response;
      }
    },
);

export const getBooking = createAsyncThunk(
    "patientForm/getBooking",
    async (booking_id:string, {rejectWithValue, dispatch}) => {
      const response = await get("home4/bookings/" + booking_id);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("GET.BOOKING.FAILED"));
        return rejectWithValue("Cannot GET booking");
      } else {
        dispatch(displayToastSuccess("GET.BOOKING.SUCCESS"));
        return response;
      }
    },
);

export const editBooking = createAsyncThunk(
    "patientForm/editBooking",
    async ({booking_id, bookingEditData}:{booking_id:string, bookingEditData:any}, {dispatch, rejectWithValue}) => {
      const response = await post("home4/bookings/" + booking_id, bookingEditData);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("EDIT.BOOKING.FAILED"));
        return rejectWithValue("Cannot EDIT booking");
      } else {
        dispatch(displayToastSuccess("EDIT.BOOKING.SUCCESS"));
        return response;
      }
    },
);
export const getAvailability = createAsyncThunk(
    "patientForm/getAvailability",
    async ({checkIn, checkOut}:{checkIn:string | number | true | Date, checkOut: string | number | true | Date},
        {rejectWithValue, dispatch}) => {
      const check_in_date=formatDateForAvailability(checkIn);
      const check_out_date=formatDateForAvailability(checkOut);
      const response = await get(`home4/bookings/availability?check_in_date=${check_in_date}&check_out_date=${check_out_date}`);
      if (response===undefined||response.success==false) {
        // dispatch(displayToastError("GET.AVAILABILITY.FAILED"));
        return rejectWithValue("Cannot get availability");
      } else {
        // dispatch(displayToastSuccess("GET.AVAILABILITY.SUCCESS"));
        return response;
      }
    },
);
export const getInsuranceCompanies = createAsyncThunk(
    "patientForm/getInsuranceCompanies",
    async (arg, {rejectWithValue, dispatch}) => {
      const response = await get("masterdata/insurances");
      if (response===undefined||response.success==false) {
        // dispatch(displayToastError("GET.INSURANCE.COMPANIES.FAILED"));
        return rejectWithValue("Cannot get insurance companies");
      } else {
        // dispatch(displayToastSuccess("GET.INSURANCE.COMPANIES.SUCCESS"));
        return response;
      }
    },
);

export const getDiagnosis = createAsyncThunk(
    "patientForm/getDiagnosis",
    async ({searchString, lang}:{searchString:string, lang: string}, {dispatch, rejectWithValue}) => {
      const response = await get("masterdata/ICD10/"+encodeURIComponent(searchString));
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("GET.DIAGNOSTICS.FAILED"));
        return rejectWithValue("Cannot get diagnostics");
      } else {
        dispatch(displayToastSuccess("GET.DIAGNOSTICS.SUCCESS"));
        return response;
      }
    },
);

export interface patientFormState {
  patientFields: {
    [key:string]:string | number | boolean | Date

  },
  medicalInforamtionFields: {
    [key:string]:string | number | boolean | Date | diagnosisType
  },
 bookingFields: any | [any],
  availableAppartments:availableAppartmentType[],
  error: {[key:string]:boolean},
  loading:{
    postBooking:string,
    getBooking:string,
    editBooking:string,
    getAvailability:string,
    getInsuranceCompanies:string,
  },
  fieldLoading:{
    getDiagnosis:string,
  }
  insurances:insuranceCompaniesType[],
  diagnostics:diagnosisType[],

}

const initialState: patientFormState = {
  patientFields: Object
      .keys(fieldsInfo)
      .filter((e)=>fieldsInfo[e].page==="patient")
      .reduce((a, e)=>({...a, [e]: fieldsInfo[e].initialValue}), {}),
  medicalInforamtionFields: Object
      .keys(fieldsInfo)
      .filter((e)=>fieldsInfo[e].page==="medicalInformation")
      .reduce((a, e)=>({...a, [e]: fieldsInfo[e].initialValue}), {}),
  bookingFields: Object
      .keys(fieldsInfo)
      .filter((e)=>fieldsInfo[e].page==="booking")
      .reduce((a, e)=>({...a, [e]: fieldsInfo[e].initialValue}), {}),
  error: Object
      .keys(fieldsInfo)
      .reduce((a, e)=>({...a, [e]: false}), {}),
  loading: {
    postBooking: "idle",
    getBooking: "idle",
    editBooking: "idle",
    getAvailability: "idle",
    getInsuranceCompanies: "idle",

  },
  fieldLoading: {
    getDiagnosis: "idle",
  },
  availableAppartments: [],
  insurances: [],
  diagnostics: [],
};


export const paientFormSlice = createSlice({
  name: "patientForm",
  initialState,
  reducers: {
    clearPatientFormFields: (state)=>{
      state.patientFields=initialState.patientFields;
      state.medicalInforamtionFields=initialState.medicalInforamtionFields;
      state.bookingFields=initialState.bookingFields;
    },
    changePatientFieldValue: (state, action: PayloadAction<{field:string, value:any}>) => {
      state.patientFields[action.payload.field] = action.payload.value;
      state.error[action.payload.field] = fieldsInfo[action.payload.field].validate(action.payload.value);
    },
    changeMedicalInformationFieldValue: (state, action: PayloadAction<{field:string, value:any}>) => {
      state.medicalInforamtionFields[action.payload.field] = action.payload.value;
      state.error[action.payload.field] = fieldsInfo[action.payload.field].validate(action.payload.value);
    },
    changeBookingFieldValue: (state, action: PayloadAction<{field:string, value:any}>) => {
      state.bookingFields[action.payload.field] = action.payload.value;
      state.error[action.payload.field] = fieldsInfo[action.payload.field].validate(action.payload.value);
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(postBooking.pending, (state, action) => {
          if (state.loading.postBooking === "idle") {
            state.loading.postBooking = "pending";
          }
        })
        .addCase(postBooking.fulfilled, (state, action) => {
          if (
            state.loading.postBooking === "pending"
          ) {
            state.loading.postBooking = "idle";
          }
        })
        .addCase(postBooking.rejected, (state, action) => {
          if (
            state.loading.postBooking === "pending"
          ) {
            state.loading.postBooking = "idle";
          }
        })
        .addCase(getBooking.pending, (state, action) => {
          if (state.loading.getBooking === "idle") {
            state.loading.getBooking = "pending";
          }
        })
        .addCase(getBooking.fulfilled, (state, action) => {
          if (
            state.loading.getBooking === "pending"
          ) {
            state.bookingFields=action.payload.result;
            state.bookingFields.appartment_id=action.payload.result.appartment?.appartment_id;
            state.medicalInforamtionFields=action.payload.result.medical_information===""?
                      initialState.medicalInforamtionFields:action.payload.result.medical_information;
            state.patientFields=action.payload.result.patient;
            state.loading.getBooking = "idle";
          }
        })
        .addCase(getBooking.rejected, (state, action) => {
          if (
            state.loading.getBooking === "pending"
          ) {
            state.loading.getBooking= "idle";
          }
        })
        .addCase(editBooking.pending, (state, action) => {
          if (state.loading.editBooking === "idle") {
            state.loading.editBooking = "pending";
          }
          console.log("idle", action);
        })
        .addCase(editBooking.fulfilled, (state, action) => {
          if (
            state.loading.editBooking === "pending"
          ) {
            state.loading.editBooking = "idle";
          }
        })
        .addCase(editBooking.rejected, (state, action) => {
          if (
            state.loading.editBooking === "pending"
          ) {
            state.loading.editBooking = "idle";
          }
        })
        .addCase(getAvailability.pending, (state, action) => {
          if (state.loading.getAvailability === "idle") {
            state.loading.getAvailability = "pending";
          }
          console.log("idle", action);
        })
        .addCase(getAvailability.fulfilled, (state, action) => {
          if (
            state.loading.getAvailability === "pending"
          ) {
            state.loading.getAvailability = "idle";
            state.availableAppartments=action.payload.result.filter((appartment)=>!appartment.deleted);
          }
        })
        .addCase(getAvailability.rejected, (state, action) => {
          if (
            state.loading.getAvailability === "pending"
          ) {
            state.loading.getAvailability = "idle";
          }
        })
        .addCase(getInsuranceCompanies.pending, (state, action) => {
          if (state.loading.getInsuranceCompanies === "idle") {
            state.loading.getInsuranceCompanies = "pending";
          }
        })
        .addCase(getInsuranceCompanies.fulfilled, (state, action) => {
          if (
            state.loading.getInsuranceCompanies === "pending"
          ) {
            state.loading.getInsuranceCompanies = "idle";
            state.insurances=action.payload;
          }
        })
        .addCase(getInsuranceCompanies.rejected, (state, action) => {
          if (
            state.loading.getInsuranceCompanies === "pending"
          ) {
            state.loading.getInsuranceCompanies = "idle";
          }
        })
        .addCase(getDiagnosis.pending, (state, action) => {
          if (state.fieldLoading.getDiagnosis === "idle") {
            state.fieldLoading.getDiagnosis = "pending";
          }
        })
        .addCase(getDiagnosis.fulfilled, (state, action) => {
          if (
            state.fieldLoading.getDiagnosis === "pending"
          ) {
            state.fieldLoading.getDiagnosis = "idle";
            state.diagnostics=action.payload;
          }
        })
        .addCase(getDiagnosis.rejected, (state, action) => {
          if (
            state.fieldLoading.getDiagnosis === "pending"
          ) {
            state.fieldLoading.getDiagnosis = "idle";
          }
        });
  },

});

export const {
  changePatientFieldValue,
  changeMedicalInformationFieldValue,
  changeBookingFieldValue,
  clearPatientFormFields,
} = paientFormSlice.actions;

export const selectPatientFields = (state: RootState) => state.patientForm.patientFields;
export const selectMedicalInformationFields = (state: RootState) => state.patientForm.medicalInforamtionFields;
export const selectBookingFields = (state: RootState) => state.patientForm.bookingFields;
export const selectErrorFields = (state: RootState) => state.patientForm.error;
export const selectLoadingPatientForm = (state: RootState) => state.patientForm.loading;
export const selectAvailableAppartments=(state: RootState) => state.patientForm.availableAppartments;
export const selectFieldLoadingPatientForm = (state: RootState) => state.patientForm.fieldLoading;
export const selectInsurances=(state: RootState) => state.patientForm.insurances;
export const selectDiagnostics=(state: RootState) => state.patientForm.diagnostics;


export default paientFormSlice.reducer;
