const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
import {acquireAccessToken} from "utils/msalProvider";


export const get = async (url, responseType?, JWTtoken?) => {
  const token = JWTtoken??await acquireAccessToken();
  if (token) {
    return fetch(REACT_APP_API_URL + url, {
      method: "GET",
      mode: "cors",
      headers: {
        // "X-custom-header": "debug",
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + token,
        "Accept": "application/json",
      },
    })
        .then((response) => {
          switch (response.status) {
            case 400:
              console.error("[400] ON ", url);
              break;
            case 401:
              console.error("[401] ON ", url);
              // logout();
              break;
            case 404:
              console.error("[404] ON ", url);
              break;
            case 500:
              console.error("[500] ON ", url);
              throw new Error("Internal Server Error");
              break;
            default:
              // success
              break;
          }
          switch (responseType) {
            case "blob":
              return response.blob();
            default:
              return response.json();
          }
        })
        .catch((error) => {
          console.error("[API]" + error);
        });
  }
};

export const post = async (url, body, JWTToken?) => {
  const token = JWTToken ?? await acquireAccessToken();
  try {
    const res = await fetch(REACT_APP_API_URL + url, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: body && JSON.stringify(body), // for deletefiles method that has no body
    });

    if (!res.ok) {
      throw new Error(`HTTP error! Status: ${res.status}`);
    }

    return res.json();
  } catch (error) {
    console.error("[API] Error:", error);
    // Handle the error as needed
    throw error; // Re-throw the error for the caller to handle if necessary
  }
};
