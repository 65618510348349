import {configureStore, ThunkAction, Action} from "@reduxjs/toolkit";
import rootReducer from "store/reducers/rootReducer";
import {createLogger} from "redux-logger";


const logger = createLogger({
  // ...options
  collapsed: true,
});


export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(logger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
