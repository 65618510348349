

import React, {useEffect, useState} from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Pagination,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useAnyDispatch, UseAppSelector} from "store/hooks";
import ListCard from "components/ListCard";
import {
  fetchSensors,
  selectSensors,
  selectLoadingSensorList,
  deleteSensor,
  getDataForCsv,
} from "./SensorListSlice";
import Backdrop from "components/form/Backdrop";
import {useNavigate} from "react-router-dom";
import {saveCsv} from "utils/commonFunctions";


export function SensorList() {
  const {t} = useTranslation();
  const dispatch=useAnyDispatch();
  const navigate=useNavigate();
  const [page, setPage]=useState(0);
  const [total, setTotal]=useState(0);

  const loading=UseAppSelector(selectLoadingSensorList);
  const sensors=UseAppSelector(selectSensors);


  useEffect(()=>{
    dispatch(fetchSensors(page))
        .then((res)=> {
          setTotal(res.payload.total);
        });
  }, [page]);

  const editClickHandler=(id:number)=>{
    navigate(`/editSensor/${id}`, {replace: false});
  };


  const saveAsCsvHandler=(id:number)=>{
    dispatch(getDataForCsv(id))
        .then((res)=> {
          console.log(res);
          saveCsv(res.payload.result );
        });
  };

  const deleteClickHandler=(id:number)=>{
    dispatch(deleteSensor(id));
  };
  return (
    <Grid container justifyContent={"space-around"}>
      <Backdrop open={Object.values(loading).includes("pending")}/>
      <Grid item xs={12} marginBottom={3}>
        <Card variant="outlined">
          <CardContent>
            <Grid item container xs={12} alignItems='center' justifyContent='space-between'>
              <Grid item xs={12}>
                <Typography variant="h5">{t("sensors.list")}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item container spacing={1}>
        {sensors&&sensors.map((sensor, index)=>
          <Grid item xs={12} key={index}>
            <ListCard
              subtitle={sensor?.room?.appartment?.name?.toString() + ", " + sensor?.room?.name?.toString()}
              dataCyId={sensor.sensor_id}
              title={sensor.name}
              body={<Typography>{sensor.sensor_type}</Typography>}
              page={page}
              CollapseContent={<>
                <Typography>{t("sensors.room")} {sensor.room.name}</Typography>
                <Typography>{t("sensors.status")}</Typography>
              </>}
              actionDropdown={[{name: "Delete", datacy: "deleteSensor_"+ sensor.sensor_id, action: ()=>deleteClickHandler(sensor.sensor_id)},
                {name: " Edit", datacy: "editSensor_"+ sensor.sensor_id, action: ()=>editClickHandler(sensor.sensor_id)},
                {name: "Save as CSV", action: ()=>saveAsCsvHandler(sensor.sensor_id)}]}/>
          </Grid>,
        )}
      </Grid>
      <Grid item marginTop={2} >
        <Card>
          <Pagination
            onChange={(event, newPage)=>setPage(newPage-1)}
            count={Math.ceil(total/5)}
            showFirstButton
            showLastButton
          />
        </Card>
      </Grid>
    </Grid>
  );
}
