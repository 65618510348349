export const formatDateDisplay = (dateString) => {
  const dateParts = dateString ? dateString.split("T")[0].split("-") : null;
  return dateParts ? `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}` : "";
};

export const formatDateForAvailability=(dateString) => {
  const parsedDate= Date.parse(dateString)&&new Date(dateString).toISOString().slice(0, 19).replace("T", " "); ;
  return parsedDate ? parsedDate : "";
};

export const correctDateFormat = (date) => {
  if (date && date!=="Invalid Date") {
    return new Date(Date.UTC(date?.getFullYear(), date?.getMonth(), date?.getDate()));
  }
};
