
import {createSlice} from "@reduxjs/toolkit";

const UserReducerSlices = createSlice({
  name: "UserReducer",
  initialState: {
    user: {
      id: "",
      givenName: "",
      surname: "",
      groups: ["patientstrenght"],
    },
    openVerifiedDialog: false,
  },
  reducers: {
    isSavingSignature: (state, {payload}) =>( {
      ...state,
      isSavingSignature: payload,
    }),
    onReceivedUserData: (state, action)=>({
      ...state,
      user: action.payload,
      openVerifiedDialog: ! action.payload?.groups?.includes("verified"),
    }),
    closeVerifiedDialog: (state)=>({
      ...state,
      openVerifiedDialog: false,
    }),
  },
});


export const {
  onReceivedUserGroups,
  onReceivedUserData,
  closeVerifiedDialog,
  isSavingSignature,
} = UserReducerSlices.actions;

export default UserReducerSlices.reducer;
