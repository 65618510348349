import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {enUS, de} from "date-fns/esm/locale";
import {FaUserEdit} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import CountryDropdown from "components/form/CountryDropdown";
import {UseAppSelector, UseAppDispatch} from "store/hooks";
import {
  selectPatientFields,
  changePatientFieldValue,
  selectErrorFields,
  selectBookingFields,
  changeBookingFieldValue,
  selectInsurances,
} from "../patientFormSlice";


export function PatientRegistrationForm() {
  const {t, i18n} = useTranslation();
  const dispatch = UseAppDispatch();

  const patientFieldsStoreValues = UseAppSelector(selectPatientFields);
  const bookingFieldsStoreValues=UseAppSelector(selectBookingFields);
  const errorData=UseAppSelector(selectErrorFields);
  const insuranceCompanies=UseAppSelector(selectInsurances);

  const defaultInsuranceCompany={
    COM: "",
    DEL: "",
    DESCR1: "",
    DESCR2: "",
    GLN: "",
    LANG: "",
    ROLE: "",
    email: "",
    email_med: "",
    email_vent: "",
  };


  let selectedInsuranceCompany;
  selectedInsuranceCompany=insuranceCompanies?.filter((insurance)=>insurance.GLN===patientFieldsStoreValues.insurance_gln)[0];
  selectedInsuranceCompany=selectedInsuranceCompany?selectedInsuranceCompany:defaultInsuranceCompany;


  return (
    <Card sx={{width: "100%", padding: 3}}>
      <CardHeader
        className="p-0 pb-3"
        avatar={
          <Avatar aria-label="recipe" style={{
            color: "#63c2de",
            backgroundColor: "#f0f3f5",
          }}>
            <FaUserEdit data-toggle="popover" data-content="Disabled popover" />
          </Avatar>
        }
        title={t("newrequest.label.patient.details")}
      />
      <Grid container spacing={3} paddingBottom={3}>
        <Grid item xs={6}>
          <TextField
            onChange={(event)=>dispatch(changePatientFieldValue({field: "first_name", value: event?.target.value}))}
            value={patientFieldsStoreValues.first_name}
            error={errorData.first_name}
            label= {t("patient.name")}
            inputProps={{"data-cy": "firstName"}}
            fullWidth
            variant="standard" />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={(event)=>dispatch(changePatientFieldValue({field: "last_name", value: event?.target.value}))}
            value={patientFieldsStoreValues.last_name}
            error={errorData.last_name}
            inputProps={{"data-cy": "lastName"}}
            label= {t("patient.surname")}
            fullWidth
            variant="standard" />
        </Grid>


        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language==="DE"?de:enUS}>
            <DesktopDatePicker
              value={patientFieldsStoreValues.date_of_birth}
              // error={errorData.date_of_birth}
              openTo="year"
              views={["year", "month", "day"]}
              inputFormat="dd/MM/yyyy"
              onChange={(date:any)=>dispatch(changePatientFieldValue({field: "date_of_birth", value: date}))}
              label={t("patient.dateOfBirth")}
              renderInput={(params) => <TextField data-cy={"dateOfBirth"} {...params} />}
            >
            </DesktopDatePicker>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth data-cy={"gender"}>
            <InputLabel >{t("patient.gender")}</InputLabel>
            <Select
              id="demo-simple-select"
              value={patientFieldsStoreValues.gender}
              error={errorData.gender}
              label={t("patient.gender")}
              onChange={(event)=>dispatch(changePatientFieldValue({field: "gender", value: event?.target.value}))}
            >
              <MenuItem value='Man'>{t("patient.gender.man")}</MenuItem>
              <MenuItem value="Woman">{t("patient.gender.woman")}</MenuItem>
              <MenuItem value='Other'>{t("patient.gender.other")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <TextField
            onChange={(event)=>dispatch(changePatientFieldValue({field: "street", value: event?.target.value}))}
            value={patientFieldsStoreValues.street}
            error={errorData.street}
            inputProps={{"data-cy": "street"}}
            label= {t("patient.street")}
            fullWidth
            variant="standard" />
        </Grid>
        <Grid item xs={2}>
          <TextField
            onChange={(event)=>dispatch(changePatientFieldValue({field: "post_code", value: event?.target.value}))}
            value={patientFieldsStoreValues.post_code}
            error={errorData.post_code}
            label= {t("patient.postalCode")}
            inputProps={{"data-cy": "postalCode"}}
            fullWidth
            variant="standard" />
        </Grid>
        <Grid item xs={3}>
          <TextField
            onChange={(event)=>dispatch(changePatientFieldValue({field: "city", value: event?.target.value}))}
            value={patientFieldsStoreValues.city}
            error={errorData.city}
            label= {t("patient.city")}
            inputProps={{"data-cy": "city"}}
            fullWidth
            variant="standard" />
        </Grid>
        <Grid item xs={2}>
          <CountryDropdown
            onChange={(event:React.SyntheticEvent)=>{
              console.log(event);
              dispatch(changePatientFieldValue({field: "country", value: event}));
            }
            }
            label= {t("residence.country")}
            error={errorData.country}
            value={String(patientFieldsStoreValues.country)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            onChange={(event)=>dispatch(changePatientFieldValue({field: "email", value: event?.target.value}))}
            value={patientFieldsStoreValues.email}
            error={errorData.email}
            inputProps={{"data-cy": "email"}}
            label= {t("patient.emergency.email")}
            fullWidth
            variant="standard" />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth data-cy={"contactMethod"}>
            <InputLabel >{t("patient.contactMethod")}</InputLabel>
            <Select
              value={patientFieldsStoreValues.preferred_contact_method}
              error={errorData.preferred_contact_method}
              label={t("patient.contactMethod")}
              onChange={(event)=>dispatch(changePatientFieldValue({field: "preferred_contact_method", value: event?.target.value}))}
            >
              <MenuItem value='Mail'>{t("patient.contact.mail")}</MenuItem>
              <MenuItem value="Phone">{t("patient.contact.phone")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <TextField
              onChange={(event)=>dispatch(changePatientFieldValue({field: "phone", value: event?.target.value}))}
              value={patientFieldsStoreValues.phone}
              error={errorData.phone}
              inputProps={{"data-cy": "phone"}}
              label= {t("patient.emergency.phone")}
              fullWidth
              variant="standard" />
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item container xs={12} paddingBottom={3} paddingTop={3}>
            <Grid item xs={6}>
              <Autocomplete
                id='insurances'
                options={insuranceCompanies??[]}
                value={selectedInsuranceCompany}
                onChange={(event, value)=>{
                  dispatch(changePatientFieldValue({field: "insurance", value: value?.DESCR1}));
                  dispatch(changePatientFieldValue({field: "insurance_gln", value: value?.GLN}));
                }}
                isOptionEqualToValue={(option:any, value:any)=>
                  option?.GLN===value.GLN||value.GLN===""}
                getOptionLabel={(option)=>option!==defaultInsuranceCompany?`${option.DESCR1}${option.DESCR2?" "+option.DESCR2:""}`:""}
                renderInput={(params) => <TextField data-cy={"insurance"} {...params} label={t("patient.insurance")}/>}/>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <FormControl >
              <FormLabel>{t("checklistA")}</FormLabel>
              <RadioGroup
                value={bookingFieldsStoreValues.check_list}
                onChange={(event:any)=>dispatch(changeBookingFieldValue({field: "check_list", value: event.target.value==="true"}))}
              >
                <FormControlLabel data-cy={"checkListA_yes"} value={true} control={<Radio />} label={`${i18n.t("yes")}`}/>
                <FormControlLabel data-cy={"checkListA_no"} value={false} control={<Radio />} label={`${i18n.t("no")}`}/>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <FormLabel>{t("informationSheet")}</FormLabel>
              <RadioGroup
                value={bookingFieldsStoreValues.information_sheet}
                onChange={(event:any)=>dispatch(changeBookingFieldValue({field: "information_sheet", value: event.target.value==="true"}))}
              >
                <FormControlLabel data-cy={"informationSheet_yes"} value={true} control={<Radio />} label={`${i18n.t("yes")}`}/>
                <FormControlLabel data-cy={"informationSheet_no"} value={false} control={<Radio />} label={`${i18n.t("no")}`}/>
              </RadioGroup>
            </FormControl>

          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <FormLabel>{t("selectionCriteria")}</FormLabel>
              <RadioGroup
                value={bookingFieldsStoreValues.selection_criteria}
                onChange={(event:any)=>dispatch(changeBookingFieldValue({field: "selection_criteria", value: event.target.value==="true"}))}
              >
                <FormControlLabel data-cy={"selectionCriteria_yes"} value={true} control={<Radio />} label={`${i18n.t("yes")}`}/>
                <FormControlLabel data-cy={"selectionCriteria_no"} value={false} control={<Radio />} label={`${i18n.t("no")}`}/>
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <FormLabel>{t("riskAcceptance")}</FormLabel>
              <RadioGroup
                value={bookingFieldsStoreValues.risk_acceptance}
                onChange={(event:any)=>dispatch(changeBookingFieldValue({field: "risk_acceptance", value: event.target.value==="true"}))}
              >
                <FormControlLabel data-cy={"riskAcceptance_yes"} value={true} control={<Radio />} label={`${i18n.t("yes")}`}/>
                <FormControlLabel data-cy={"riskAcceptance_no"} value={false} control={<Radio />} label={`${i18n.t("no")}`}/>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
