import React, {ReactEventHandler} from "react";
import {
  Autocomplete,
  TextField,
  Box,
} from "@mui/material";


type CountryDropdownProps={
  value:any,
  onChange:ReactEventHandler,
    label:string,
    error:boolean,
}
import i18n from "utils/i18n";

export default ({
  onChange,
  value,
  error,
  label,
}:CountryDropdownProps) =>
  <Autocomplete
    autoHighlight
    autoComplete
    options={countries.filter((e)=>e.lang===i18n.language) }
    getOptionLabel={(option:any) => option?.code??option}
    renderOption={(props, option) => (
      <Box component="li" sx={{"& > img": {mr: 2, flexShrink: 0}}} {...props}>
        <img
          loading="lazy"
          width="20"
          // TODO_AnGh: Does Germany need to be D and not DE?
          src={`https://flagcdn.com/w20/${option.code==="D"?"de":option.code.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${option.code==="D"?"de":option.code.toLowerCase()}.png 2x`}
          alt=""
        />
        {option.code} {option.label}
      </Box>
    )}
    isOptionEqualToValue={(option:CountryType, value:CountryType|string)=>option?.code===value||option?.code===(value as CountryType).code}
    value={value}
    onChange={(event, value)=>{
      onChange(value?.code||"");
    }}
    // {...input}
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        variant="standard"
        error={error}
        inputProps={{
          ...params.inputProps,
          "data-cy": "country",
          "autoComplete": "new-password", // disable autocomplete and autofill
        }}
      />
    )}
  />;


interface CountryType {
  lang: string;
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

const countries: readonly CountryType[] = [
  {lang: "EN", code: "", label: "", phone: ""},
  {lang: "DE", code: "", label: "", phone: ""},
  {lang: "FR", code: "", label: "", phone: ""},
  {lang: "EN", code: "CH", label: "Switzerland", phone: "41"},
  {lang: "EN", code: "AD", label: "Andorra", phone: "376"},
  {lang: "EN", code: "AE", label: "United Arab Emirates", phone: "971"},
  {lang: "EN", code: "AF", label: "Afghanistan", phone: "93"},
  {lang: "EN", code: "AG", label: "Antigua and Barbuda", phone: "1-268"},
  {lang: "EN", code: "AI", label: "Anguilla", phone: "1-264"},
  {lang: "EN", code: "AL", label: "Albania", phone: "355"},
  {lang: "EN", code: "AM", label: "Armenia", phone: "374"},
  {lang: "EN", code: "AO", label: "Angola", phone: "244"},
  {lang: "EN", code: "AQ", label: "Antarctica", phone: "672"},
  {lang: "EN", code: "AR", label: "Argentina", phone: "54"},
  {lang: "EN", code: "AS", label: "American Samoa", phone: "1-684"},
  {lang: "EN", code: "AT", label: "Austria", phone: "43"},
  {lang: "EN", code: "AU", label: "Australia", phone: "61", suggested: true},
  {lang: "EN", code: "AW", label: "Aruba", phone: "297"},
  {lang: "EN", code: "AX", label: "Alland Islands", phone: "358"},
  {lang: "EN", code: "AZ", label: "Azerbaijan", phone: "994"},
  {lang: "EN", code: "BA", label: "Bosnia and Herzegovina", phone: "387"},
  {lang: "EN", code: "BB", label: "Barbados", phone: "1-246"},
  {lang: "EN", code: "BD", label: "Bangladesh", phone: "880"},
  {lang: "EN", code: "BE", label: "Belgium", phone: "32"},
  {lang: "EN", code: "BF", label: "Burkina Faso", phone: "226"},
  {lang: "EN", code: "BG", label: "Bulgaria", phone: "359"},
  {lang: "EN", code: "BH", label: "Bahrain", phone: "973"},
  {lang: "EN", code: "BI", label: "Burundi", phone: "257"},
  {lang: "EN", code: "BJ", label: "Benin", phone: "229"},
  {lang: "EN", code: "BL", label: "Saint Barthelemy", phone: "590"},
  {lang: "EN", code: "BM", label: "Bermuda", phone: "1-441"},
  {lang: "EN", code: "BN", label: "Brunei Darussalam", phone: "673"},
  {lang: "EN", code: "BO", label: "Bolivia", phone: "591"},
  {lang: "EN", code: "BR", label: "Brazil", phone: "55"},
  {lang: "EN", code: "BS", label: "Bahamas", phone: "1-242"},
  {lang: "EN", code: "BT", label: "Bhutan", phone: "975"},
  {lang: "EN", code: "BV", label: "Bouvet Island", phone: "47"},
  {lang: "EN", code: "BW", label: "Botswana", phone: "267"},
  {lang: "EN", code: "BY", label: "Belarus", phone: "375"},
  {lang: "EN", code: "BZ", label: "Belize", phone: "501"},
  {lang: "EN", code: "CA", label: "Canada", phone: "1", suggested: true},
  {lang: "EN", code: "CC", label: "Cocos (Keeling) Islands", phone: "61"},
  {lang: "EN", code: "CD", label: "Congo, Democratic Republic of the", phone: "243"},
  {lang: "EN", code: "CF", label: "Central African Republic", phone: "236"},
  {lang: "EN", code: "CG", label: "Congo, Republic of the", phone: "242"},
  {lang: "EN", code: "CI", label: "Cote d'Ivoire", phone: "225"},
  {lang: "EN", code: "CK", label: "Cook Islands", phone: "682"},
  {lang: "EN", code: "CL", label: "Chile", phone: "56"},
  {lang: "EN", code: "CM", label: "Cameroon", phone: "237"},
  {lang: "EN", code: "CN", label: "China", phone: "86"},
  {lang: "EN", code: "CO", label: "Colombia", phone: "57"},
  {lang: "EN", code: "CR", label: "Costa Rica", phone: "506"},
  {lang: "EN", code: "CU", label: "Cuba", phone: "53"},
  {lang: "EN", code: "CV", label: "Cape Verde", phone: "238"},
  {lang: "EN", code: "CW", label: "Curacao", phone: "599"},
  {lang: "EN", code: "CX", label: "Christmas Island", phone: "61"},
  {lang: "EN", code: "CY", label: "Cyprus", phone: "357"},
  {lang: "EN", code: "CZ", label: "Czech Republic", phone: "420"},
  {lang: "EN", code: "D", label: "Germany", phone: "49", suggested: true},
  {lang: "EN", code: "DJ", label: "Djibouti", phone: "253"},
  {lang: "EN", code: "DK", label: "Denmark", phone: "45"},
  {lang: "EN", code: "DM", label: "Dominica", phone: "1-767"},
  {lang: "EN", code: "DO", label: "Dominican Republic", phone: "1-809"},
  {lang: "EN", code: "DZ", label: "Algeria", phone: "213"},
  {lang: "EN", code: "EC", label: "Ecuador", phone: "593"},
  {lang: "EN", code: "EE", label: "Estonia", phone: "372"},
  {lang: "EN", code: "EG", label: "Egypt", phone: "20"},
  {lang: "EN", code: "EH", label: "Western Sahara", phone: "212"},
  {lang: "EN", code: "ER", label: "Eritrea", phone: "291"},
  {lang: "EN", code: "ES", label: "Spain", phone: "34"},
  {lang: "EN", code: "ET", label: "Ethiopia", phone: "251"},
  {lang: "EN", code: "FI", label: "Finland", phone: "358"},
  {lang: "EN", code: "FJ", label: "Fiji", phone: "679"},
  {lang: "EN", code: "FK", label: "Falkland Islands (Malvinas)", phone: "500"},
  {lang: "EN", code: "FM", label: "Micronesia, Federated States of", phone: "691"},
  {lang: "EN", code: "FO", label: "Faroe Islands", phone: "298"},
  {lang: "EN", code: "FR", label: "France", phone: "33", suggested: true},
  {lang: "EN", code: "GA", label: "Gabon", phone: "241"},
  {lang: "EN", code: "GB", label: "United Kingdom", phone: "44"},
  {lang: "EN", code: "GD", label: "Grenada", phone: "1-473"},
  {lang: "EN", code: "GE", label: "Georgia", phone: "995"},
  {lang: "EN", code: "GF", label: "French Guiana", phone: "594"},
  {lang: "EN", code: "GG", label: "Guernsey", phone: "44"},
  {lang: "EN", code: "GH", label: "Ghana", phone: "233"},
  {lang: "EN", code: "GI", label: "Gibraltar", phone: "350"},
  {lang: "EN", code: "GL", label: "Greenland", phone: "299"},
  {lang: "EN", code: "GM", label: "Gambia", phone: "220"},
  {lang: "EN", code: "GN", label: "Guinea", phone: "224"},
  {lang: "EN", code: "GP", label: "Guadeloupe", phone: "590"},
  {lang: "EN", code: "GQ", label: "Equatorial Guinea", phone: "240"},
  {lang: "EN", code: "GR", label: "Greece", phone: "30"},
  {lang: "EN", code: "GS", label: "South Georgia and the South Sandwich Islands", phone: "500"},
  {lang: "EN", code: "GT", label: "Guatemala", phone: "502"},
  {lang: "EN", code: "GU", label: "Guam", phone: "1-671"},
  {lang: "EN", code: "GW", label: "Guinea-Bissau", phone: "245"},
  {lang: "EN", code: "GY", label: "Guyana", phone: "592"},
  {lang: "EN", code: "HK", label: "Hong Kong", phone: "852"},
  {lang: "EN", code: "HM", label: "Heard Island and McDonald Islands", phone: "672"},
  {lang: "EN", code: "HN", label: "Honduras", phone: "504"},
  {lang: "EN", code: "HR", label: "Croatia", phone: "385"},
  {lang: "EN", code: "HT", label: "Haiti", phone: "509"},
  {lang: "EN", code: "HU", label: "Hungary", phone: "36"},
  {lang: "EN", code: "ID", label: "Indonesia", phone: "62"},
  {lang: "EN", code: "IE", label: "Ireland", phone: "353"},
  {lang: "EN", code: "IL", label: "Israel", phone: "972"},
  {lang: "EN", code: "IM", label: "Isle of Man", phone: "44"},
  {lang: "EN", code: "IN", label: "India", phone: "91"},
  {lang: "EN", code: "IO", label: "British Indian Ocean Territory", phone: "246"},
  {lang: "EN", code: "IQ", label: "Iraq", phone: "964"},
  {lang: "EN", code: "IR", label: "Iran, Islamic Republic of", phone: "98"},
  {lang: "EN", code: "IS", label: "Iceland", phone: "354"},
  {lang: "EN", code: "IT", label: "Italy", phone: "39"},
  {lang: "EN", code: "JE", label: "Jersey", phone: "44"},
  {lang: "EN", code: "JM", label: "Jamaica", phone: "1-876"},
  {lang: "EN", code: "JO", label: "Jordan", phone: "962"},
  {lang: "EN", code: "JP", label: "Japan", phone: "81", suggested: true},
  {lang: "EN", code: "KE", label: "Kenya", phone: "254"},
  {lang: "EN", code: "KG", label: "Kyrgyzstan", phone: "996"},
  {lang: "EN", code: "KH", label: "Cambodia", phone: "855"},
  {lang: "EN", code: "KI", label: "Kiribati", phone: "686"},
  {lang: "EN", code: "KM", label: "Comoros", phone: "269"},
  {lang: "EN", code: "KN", label: "Saint Kitts and Nevis", phone: "1-869"},
  {lang: "EN", code: "KP", label: "Korea, Democratic People's Republic of", phone: "850"},
  {lang: "EN", code: "KR", label: "Korea, Republic of", phone: "82"},
  {lang: "EN", code: "KW", label: "Kuwait", phone: "965"},
  {lang: "EN", code: "KY", label: "Cayman Islands", phone: "1-345"},
  {lang: "EN", code: "KZ", label: "Kazakhstan", phone: "7"},
  {lang: "EN", code: "LA", label: "Lao People's Democratic Republic", phone: "856"},
  {lang: "EN", code: "LB", label: "Lebanon", phone: "961"},
  {lang: "EN", code: "LC", label: "Saint Lucia", phone: "1-758"},
  {lang: "EN", code: "LI", label: "Liechtenstein", phone: "423"},
  {lang: "EN", code: "LK", label: "Sri Lanka", phone: "94"},
  {lang: "EN", code: "LR", label: "Liberia", phone: "231"},
  {lang: "EN", code: "LS", label: "Lesotho", phone: "266"},
  {lang: "EN", code: "LT", label: "Lithuania", phone: "370"},
  {lang: "EN", code: "LU", label: "Luxembourg", phone: "352"},
  {lang: "EN", code: "LV", label: "Latvia", phone: "371"},
  {lang: "EN", code: "LY", label: "Libya", phone: "218"},
  {lang: "EN", code: "MA", label: "Morocco", phone: "212"},
  {lang: "EN", code: "MC", label: "Monaco", phone: "377"},
  {lang: "EN", code: "MD", label: "Moldova, Republic of", phone: "373"},
  {lang: "EN", code: "ME", label: "Montenegro", phone: "382"},
  {lang: "EN", code: "MF", label: "Saint Martin (French part)", phone: "590"},
  {lang: "EN", code: "MG", label: "Madagascar", phone: "261"},
  {lang: "EN", code: "MH", label: "Marshall Islands", phone: "692"},
  {lang: "EN", code: "MK", label: "Macedonia, the Former Yugoslav Republic of", phone: "389"},
  {lang: "EN", code: "ML", label: "Mali", phone: "223"},
  {lang: "EN", code: "MM", label: "Myanmar", phone: "95"},
  {lang: "EN", code: "MN", label: "Mongolia", phone: "976"},
  {lang: "EN", code: "MO", label: "Macao", phone: "853"},
  {lang: "EN", code: "MP", label: "Northern Mariana Islands", phone: "1-670"},
  {lang: "EN", code: "MQ", label: "Martinique", phone: "596"},
  {lang: "EN", code: "MR", label: "Mauritania", phone: "222"},
  {lang: "EN", code: "MS", label: "Montserrat", phone: "1-664"},
  {lang: "EN", code: "MT", label: "Malta", phone: "356"},
  {lang: "EN", code: "MU", label: "Mauritius", phone: "230"},
  {lang: "EN", code: "MV", label: "Maldives", phone: "960"},
  {lang: "EN", code: "MW", label: "Malawi", phone: "265"},
  {lang: "EN", code: "MX", label: "Mexico", phone: "52"},
  {lang: "EN", code: "MY", label: "Malaysia", phone: "60"},
  {lang: "EN", code: "MZ", label: "Mozambique", phone: "258"},
  {lang: "EN", code: "NA", label: "Namibia", phone: "264"},
  {lang: "EN", code: "NC", label: "New Caledonia", phone: "687"},
  {lang: "EN", code: "NE", label: "Niger", phone: "227"},
  {lang: "EN", code: "NF", label: "Norfolk Island", phone: "672"},
  {lang: "EN", code: "NG", label: "Nigeria", phone: "234"},
  {lang: "EN", code: "NI", label: "Nicaragua", phone: "505"},
  {lang: "EN", code: "NL", label: "Netherlands", phone: "31"},
  {lang: "EN", code: "NO", label: "Norway", phone: "47"},
  {lang: "EN", code: "NP", label: "Nepal", phone: "977"},
  {lang: "EN", code: "NR", label: "Nauru", phone: "674"},
  {lang: "EN", code: "NU", label: "Niue", phone: "683"},
  {lang: "EN", code: "NZ", label: "New Zealand", phone: "64"},
  {lang: "EN", code: "OM", label: "Oman", phone: "968"},
  {lang: "EN", code: "PA", label: "Panama", phone: "507"},
  {lang: "EN", code: "PE", label: "Peru", phone: "51"},
  {lang: "EN", code: "PF", label: "French Polynesia", phone: "689"},
  {lang: "EN", code: "PG", label: "Papua New Guinea", phone: "675"},
  {lang: "EN", code: "PH", label: "Philippines", phone: "63"},
  {lang: "EN", code: "PK", label: "Pakistan", phone: "92"},
  {lang: "EN", code: "PL", label: "Poland", phone: "48"},
  {lang: "EN", code: "PM", label: "Saint Pierre and Miquelon", phone: "508"},
  {lang: "EN", code: "PN", label: "Pitcairn", phone: "870"},
  {lang: "EN", code: "PR", label: "Puerto Rico", phone: "1"},
  {lang: "EN", code: "PS", label: "Palestine, State of", phone: "970"},
  {lang: "EN", code: "PT", label: "Portugal", phone: "351"},
  {lang: "EN", code: "PW", label: "Palau", phone: "680"},
  {lang: "EN", code: "PY", label: "Paraguay", phone: "595"},
  {lang: "EN", code: "QA", label: "Qatar", phone: "974"},
  {lang: "EN", code: "RE", label: "Reunion", phone: "262"},
  {lang: "EN", code: "RO", label: "Romania", phone: "40"},
  {lang: "EN", code: "RS", label: "Serbia", phone: "381"},
  {lang: "EN", code: "RU", label: "Russian Federation", phone: "7"},
  {lang: "EN", code: "RW", label: "Rwanda", phone: "250"},
  {lang: "EN", code: "SA", label: "Saudi Arabia", phone: "966"},
  {lang: "EN", code: "SB", label: "Solomon Islands", phone: "677"},
  {lang: "EN", code: "SC", label: "Seychelles", phone: "248"},
  {lang: "EN", code: "SD", label: "Sudan", phone: "249"},
  {lang: "EN", code: "SE", label: "Sweden", phone: "46"},
  {lang: "EN", code: "SG", label: "Singapore", phone: "65"},
  {lang: "EN", code: "SH", label: "Saint Helena", phone: "290"},
  {lang: "EN", code: "SI", label: "Slovenia", phone: "386"},
  {lang: "EN", code: "SJ", label: "Svalbard and Jan Mayen", phone: "47"},
  {lang: "EN", code: "SK", label: "Slovakia", phone: "421"},
  {lang: "EN", code: "SL", label: "Sierra Leone", phone: "232"},
  {lang: "EN", code: "SM", label: "San Marino", phone: "378"},
  {lang: "EN", code: "SN", label: "Senegal", phone: "221"},
  {lang: "EN", code: "SO", label: "Somalia", phone: "252"},
  {lang: "EN", code: "SR", label: "Suriname", phone: "597"},
  {lang: "EN", code: "SS", label: "South Sudan", phone: "211"},
  {lang: "EN", code: "ST", label: "Sao Tome and Principe", phone: "239"},
  {lang: "EN", code: "SV", label: "El Salvador", phone: "503"},
  {lang: "EN", code: "SX", label: "Sint Maarten (Dutch part)", phone: "1-721"},
  {lang: "EN", code: "SY", label: "Syrian Arab Republic", phone: "963"},
  {lang: "EN", code: "SZ", label: "Swaziland", phone: "268"},
  {lang: "EN", code: "TC", label: "Turks and Caicos Islands", phone: "1-649"},
  {lang: "EN", code: "TD", label: "Chad", phone: "235"},
  {lang: "EN", code: "TF", label: "French Southern Territories", phone: "262"},
  {lang: "EN", code: "TG", label: "Togo", phone: "228"},
  {lang: "EN", code: "TH", label: "Thailand", phone: "66"},
  {lang: "EN", code: "TJ", label: "Tajikistan", phone: "992"},
  {lang: "EN", code: "TK", label: "Tokelau", phone: "690"},
  {lang: "EN", code: "TL", label: "Timor-Leste", phone: "670"},
  {lang: "EN", code: "TM", label: "Turkmenistan", phone: "993"},
  {lang: "EN", code: "TN", label: "Tunisia", phone: "216"},
  {lang: "EN", code: "TO", label: "Tonga", phone: "676"},
  {lang: "EN", code: "TR", label: "Turkey", phone: "90"},
  {lang: "EN", code: "TT", label: "Trinidad and Tobago", phone: "1-868"},
  {lang: "EN", code: "TV", label: "Tuvalu", phone: "688"},
  {lang: "EN", code: "TW", label: "Taiwan, Province of China", phone: "886"},
  {lang: "EN", code: "TZ", label: "United Republic of Tanzania", phone: "255"},
  {lang: "EN", code: "UA", label: "Ukraine", phone: "380"},
  {lang: "EN", code: "UG", label: "Uganda", phone: "256"},
  {lang: "EN", code: "US", label: "United States", phone: "1", suggested: true},
  {lang: "EN", code: "UY", label: "Uruguay", phone: "598"},
  {lang: "EN", code: "UZ", label: "Uzbekistan", phone: "998"},
  {lang: "EN", code: "VA", label: "Holy See (Vatican City State)", phone: "379"},
  {lang: "EN", code: "VC", label: "Saint Vincent and the Grenadines", phone: "1-784"},
  {lang: "EN", code: "VE", label: "Venezuela", phone: "58"},
  {lang: "EN", code: "VG", label: "British Virgin Islands", phone: "1-284"},
  {lang: "EN", code: "VI", label: "US Virgin Islands", phone: "1-340"},
  {lang: "EN", code: "VN", label: "Vietnam", phone: "84"},
  {lang: "EN", code: "VU", label: "Vanuatu", phone: "678"},
  {lang: "EN", code: "WF", label: "Wallis and Futuna", phone: "681"},
  {lang: "EN", code: "WS", label: "Samoa", phone: "685"},
  {lang: "EN", code: "XK", label: "Kosovo", phone: "383"},
  {lang: "EN", code: "YE", label: "Yemen", phone: "967"},
  {lang: "EN", code: "YT", label: "Mayotte", phone: "262"},
  {lang: "EN", code: "ZA", label: "South Africa", phone: "27"},
  {lang: "EN", code: "ZM", label: "Zambia", phone: "260"},
  {lang: "EN", code: "ZW", label: "Zimbabwe", phone: "263"},
  // German translation for the code country
  {lang: "DE", code: "CH", label: "Schweiz", phone: "41"},
  {lang: "DE", code: "D", label: "Deutschland", phone: "49"},
  {lang: "DE", code: "AT", label: "Österreich", phone: "43"},
  {lang: "DE", code: "LU", label: "Luxemburg", phone: "352"},
  {lang: "DE", code: "AF", label: "Afghanistan", phone: "93"},
  {lang: "DE", code: "EG", label: "Ägypten", phone: "20"},
  {lang: "DE", code: "AX", label: "Åland", phone: "358"},
  {lang: "DE", code: "AL", label: "Albanien", phone: "355"},
  {lang: "DE", code: "DZ", label: "Algerien", phone: "213"},
  {lang: "DE", code: "AS", label: "Amerikanisch-Samoa", phone: "1-684"},
  {lang: "DE", code: "AD", label: "Andorra", phone: "376"},
  {lang: "DE", code: "AO", label: "Angola", phone: "244"},
  {lang: "DE", code: "AI", label: "Anguilla", phone: "1-264"},
  {lang: "DE", code: "AQ", label: "Antarktis", phone: "672"},
  {lang: "DE", code: "AG", label: "Antigua und Barbuda", phone: "1-268"},
  {lang: "DE", code: "GQ", label: "Äquatorialguinea", phone: "240"},
  {lang: "DE", code: "AR", label: "Argentinien", phone: "54"},
  {lang: "DE", code: "AM", label: "Armenien", phone: "374"},
  {lang: "DE", code: "AW", label: "Aruba", phone: "297"},
  {lang: "DE", code: "AZ", label: "Aserbaidschan", phone: "994"},
  {lang: "DE", code: "ET", label: "Äthiopien", phone: "251"},
  {lang: "DE", code: "AU", label: "Australien", phone: "61"},
  {lang: "DE", code: "BS", label: "Bahamas", phone: "1-242"},
  {lang: "DE", code: "BH", label: "Bahrain", phone: "973"},
  {lang: "DE", code: "BD", label: "Bangladesh", phone: "880"},
  {lang: "DE", code: "BB", label: "Barbados", phone: "1-246"},
  {lang: "DE", code: "BE", label: "Belgien", phone: "32"},
  {lang: "DE", code: "BZ", label: "Belize", phone: "501"},
  {lang: "DE", code: "BJ", label: "Benin", phone: "229"},
  {lang: "DE", code: "BM", label: "Bermuda", phone: "1-441"},
  {lang: "DE", code: "BT", label: "Bhutan", phone: "975"},
  {lang: "DE", code: "BO", label: "Bolivien", phone: "591"},
  {lang: "DE", code: "BA", label: "Bosnien und Herzegowina", phone: "387"},
  {lang: "DE", code: "BW", label: "Botsuana", phone: "267"},
  {lang: "DE", code: "BV", label: "Bouvetinsel", phone: "47"},
  {lang: "DE", code: "BR", label: "Brasilien", phone: "55"},
  {lang: "DE", code: "BN", label: "Brunei Darussalam", phone: "673"},
  {lang: "DE", code: "BG", label: "Bulgarien", phone: "359"},
  {lang: "DE", code: "BF", label: "Burkina Faso", phone: "226"},
  {lang: "DE", code: "BI", label: "Burundi", phone: "257"},
  {lang: "DE", code: "CV", label: "Cabo Verde", phone: "238"},
  {lang: "DE", code: "CL", label: "Chile", phone: "56"},
  {lang: "DE", code: "CN", label: "China", phone: "86"},
  {lang: "DE", code: "CK", label: "Cookinseln", phone: "682"},
  {lang: "DE", code: "CR", label: "Costa Rica", phone: "506"},
  {lang: "DE", code: "CU", label: "Cuba", phone: "53"},
  {lang: "DE", code: "DM", label: "Dominica", phone: "1-767"},
  {lang: "DE", code: "DO", label: "Dominikanische Republik", phone: "1-809"},
  {lang: "DE", code: "DJ", label: "Dschibuti", phone: "253"},
  {lang: "DE", code: "DK", label: "Dänemark", phone: "45"},
  {lang: "DE", code: "EC", label: "Ecuador", phone: "593"},
  {lang: "DE", code: "SV", label: "El Salvador", phone: "503"},
  {lang: "DE", code: "CI", label: "Elfenbeinküste", phone: "225"},
  {lang: "DE", code: "ER", label: "Eritrea", phone: "291"},
  {lang: "DE", code: "EE", label: "Estland", phone: "372"},
  {lang: "DE", code: "FK", label: "Falklandinseln", phone: "500"},
  {lang: "DE", code: "FJ", label: "Fidschi", phone: "679"},
  {lang: "DE", code: "FI", label: "Finnland", phone: "358"},
  {lang: "DE", code: "FR", label: "Frankreich", phone: "33"},
  {lang: "DE", code: "GF", label: "Französisch-Guayana", phone: "594"},
  {lang: "DE", code: "PF", label: "Französisch-Polynesien", phone: "689"},
  {lang: "DE", code: "TF", label: "Französische Südpolarterritorien", phone: "262"},
  {lang: "DE", code: "FO", label: "Färöer", phone: "298"},
  {lang: "DE", code: "GA", label: "Gabun", phone: "241"},
  {lang: "DE", code: "GM", label: "Gambia", phone: "220"},
  {lang: "DE", code: "GE", label: "Georgien", phone: "995"},
  {lang: "DE", code: "GH", label: "Ghana", phone: "233"},
  {lang: "DE", code: "GI", label: "Gibraltar", phone: "350"},
  {lang: "DE", code: "GD", label: "Grenada", phone: "1-473"},
  {lang: "DE", code: "GR", label: "Griechenland", phone: "30"},
  {lang: "DE", code: "GB", label: "Großbritannien", phone: "44"},
  {lang: "DE", code: "GL", label: "Grönland", phone: "299"},
  {lang: "DE", code: "GP", label: "Guadeloupe", phone: "590"},
  {lang: "DE", code: "GU", label: "Guam", phone: "1-671"},
  {lang: "DE", code: "GT", label: "Guatemala", phone: "502"},
  {lang: "DE", code: "GG", label: "Guernsey", phone: "44"},
  {lang: "DE", code: "GW", label: "Guinea-Bissau", phone: "245"},
  {lang: "DE", code: "GN", label: "Guinea", phone: "224"},
  {lang: "DE", code: "GY", label: "Guyana", phone: "592"},
  {lang: "DE", code: "HT", label: "Haiti", phone: "509"},
  {lang: "DE", code: "HM", label: "Heard und McDonaldinseln", phone: "672"},
  {lang: "DE", code: "HN", label: "Honduras", phone: "504"},
  {lang: "DE", code: "HK", label: "Hong Kong", phone: "852"},
  {lang: "DE", code: "IN", label: "Indien", phone: "91"},
  {lang: "DE", code: "ID", label: "Indonesien", phone: "62"},
  {lang: "DE", code: "IQ", label: "Irak", phone: "964"},
  {lang: "DE", code: "IR", label: "Iran", phone: "98"},
  {lang: "DE", code: "IE", label: "Irland", phone: "353"},
  {lang: "DE", code: "IS", label: "Island", phone: "354"},
  {lang: "DE", code: "IM", label: "Isle Of Man", phone: "44"},
  {lang: "DE", code: "IL", label: "Israel", phone: "972"},
  {lang: "DE", code: "IT", label: "Italien", phone: "39"},
  {lang: "DE", code: "JM", label: "Jamaika", phone: "1-876"},
  {lang: "DE", code: "JP", label: "Japan", phone: "81"},
  {lang: "DE", code: "YE", label: "Jemen", phone: "967"},
  {lang: "DE", code: "JE", label: "Jersey", phone: "44"},
  {lang: "DE", code: "JO", label: "Jordanien", phone: "962"},
  {lang: "DE", code: "VI", label: "Amerikanische Jungferninseln", phone: "1-340"},
  {lang: "DE", code: "VG", label: "Britische Jungferninseln", phone: "1-284"},
  {lang: "DE", code: "KY", label: "Kaimaninseln", phone: "1-345"},
  {lang: "DE", code: "KH", label: "Kambodscha", phone: "855"},
  {lang: "DE", code: "CM", label: "Kamerun", phone: "237"},
  {lang: "DE", code: "CA", label: "Kanada", phone: "1"},
  {lang: "DE", code: "KZ", label: "Kasachstan", phone: "7"},
  {lang: "DE", code: "KE", label: "Kenia", phone: "254"},
  {lang: "DE", code: "KG", label: "Kirgisistan", phone: "996"},
  {lang: "DE", code: "KI", label: "Kiribati", phone: "686"},
  {lang: "DE", code: "CC", label: "Kokosinseln", phone: "61"},
  {lang: "DE", code: "CO", label: "Kolumbien", phone: "57"},
  {lang: "DE", code: "KM", label: "Komoren", phone: "269"},
  {lang: "DE", code: "CG", label: "Kongo", phone: "242"},
  {lang: "DE", code: "HR", label: "Kroatien", phone: "385"},
  {lang: "DE", code: "KW", label: "Kuwait", phone: "965"},
  {lang: "DE", code: "LA", label: "Laos", phone: "856"},
  {lang: "DE", code: "LS", label: "Lesotho", phone: "266"},
  {lang: "DE", code: "LV", label: "Lettland", phone: "371"},
  {lang: "DE", code: "LB", label: "Libanon", phone: "961"},
  {lang: "DE", code: "LR", label: "Liberia", phone: "231"},
  {lang: "DE", code: "LY", label: "Libyen", phone: "218"},
  {lang: "DE", code: "LI", label: "Liechtenstein", phone: "423"},
  {lang: "DE", code: "LT", label: "Litauen", phone: "370"},
  {lang: "DE", code: "MO", label: "Macao", phone: "853"},
  {lang: "DE", code: "MG", label: "Madagaskar", phone: "261"},
  {lang: "DE", code: "MW", label: "Malawi", phone: "265"},
  {lang: "DE", code: "MY", label: "Malaysia", phone: "60"},
  {lang: "DE", code: "MV", label: "Maldiven", phone: "960"},
  {lang: "DE", code: "ML", label: "Mali", phone: "223"},
  {lang: "DE", code: "MT", label: "Malta", phone: "356"},
  {lang: "DE", code: "MH", label: "Marshallinseln", phone: "692"},
  {lang: "DE", code: "MQ", label: "Martinique", phone: "596"},
  {lang: "DE", code: "MR", label: "Mauretanien", phone: "222"},
  {lang: "DE", code: "MU", label: "Mauritius", phone: "230"},
  {lang: "DE", code: "YT", label: "Mayotte", phone: "262"},
  {lang: "DE", code: "MK", label: "Mazedonien", phone: "389"},
  {lang: "DE", code: "MX", label: "Mexiko", phone: "52"},
  {lang: "DE", code: "FM", label: "Mikronesien", phone: "691"},
  {lang: "DE", code: "MD", label: "Moldawien", phone: "373"},
  {lang: "DE", code: "MC", label: "Monaco", phone: "377"},
  {lang: "DE", code: "MN", label: "Mongolei", phone: "976"},
  {lang: "DE", code: "ME", label: "Montenegro", phone: "382"},
  {lang: "DE", code: "MS", label: "Montserrat", phone: "1-664"},
  {lang: "DE", code: "MA", label: "Morokko", phone: "212"},
  {lang: "DE", code: "MZ", label: "Mosambik", phone: "258"},
  {lang: "DE", code: "MM", label: "Myanmar", phone: "95"},
  {lang: "DE", code: "NA", label: "Namibia", phone: "264"},
  {lang: "DE", code: "NR", label: "Nauru", phone: "674"},
  {lang: "DE", code: "NP", label: "Nepal", phone: "977"},
  {lang: "DE", code: "NC", label: "Neukaledonien", phone: "687"},
  {lang: "DE", code: "NZ", label: "Neuseeland", phone: "64"},
  {lang: "DE", code: "NI", label: "Nicaragua", phone: "505"},
  {lang: "DE", code: "NL", label: "Niederlande", phone: "31"},
  {lang: "DE", code: "NG", label: "Nigeria", phone: "234"},
  {lang: "DE", code: "NE", label: "Niger", phone: "227"},
  {lang: "DE", code: "NU", label: "Niue", phone: "683"},
  {lang: "DE", code: "KP", label: "Nordkorea", phone: "850"},
  {lang: "DE", code: "NF", label: "Norfolkinsel", phone: "672"},
  {lang: "DE", code: "NO", label: "Norwegen", phone: "47"},
  {lang: "DE", code: "MP", label: "Nördliche Marianen", phone: "1-670"},
  {lang: "DE", code: "OM", label: "Oman", phone: "968"},
  {lang: "DE", code: "PK", label: "Pakistan", phone: "92"},
  {lang: "DE", code: "PW", label: "Palau", phone: "680"},
  {lang: "DE", code: "PS", label: "Palestina", phone: "970"},
  {lang: "DE", code: "PA", label: "Panama", phone: "507"},
  {lang: "DE", code: "PG", label: "Papua-Neuguinea", phone: "675"},
  {lang: "DE", code: "PY", label: "Paraguay", phone: "595"},
  {lang: "DE", code: "PE", label: "Peru", phone: "51"},
  {lang: "DE", code: "PH", label: "Philippinen", phone: "63"},
  {lang: "DE", code: "PN", label: "Pitcairninseln", phone: "870"},
  {lang: "DE", code: "PL", label: "Polen", phone: "48"},
  {lang: "DE", code: "PT", label: "Portugal", phone: "351"},
  {lang: "DE", code: "PR", label: "Puerto Rico", phone: "1"},
  {lang: "DE", code: "QA", label: "Qatar", phone: "974"},
  {lang: "DE", code: "RW", label: "Ruanda", phone: "250"},
  {lang: "DE", code: "RO", label: "Rumänien", phone: "40"},
  {lang: "DE", code: "RU", label: "Russland", phone: "7"},
  {lang: "DE", code: "RE", label: "Réunion", phone: "262"},
  {lang: "DE", code: "SB", label: "Salomonen", phone: "677"},
  {lang: "DE", code: "ZM", label: "Sambia", phone: "260"},
  {lang: "DE", code: "WS", label: "Samoa", phone: "685"},
  {lang: "DE", code: "SM", label: "San Marino", phone: "378"},
  {lang: "DE", code: "SA", label: "Saudi-Arabien", phone: "966"},
  {lang: "DE", code: "SE", label: "Schweden", phone: "46"},
  {lang: "DE", code: "SN", label: "Senegal", phone: "221"},
  {lang: "DE", code: "RS", label: "Serbien", phone: "381"},
  {lang: "DE", code: "SC", label: "Seychellen", phone: "248"},
  {lang: "DE", code: "SL", label: "Sierra Leone", phone: "232"},
  {lang: "DE", code: "ZW", label: "Simbabwe", phone: "263"},
  {lang: "DE", code: "SG", label: "Singapur", phone: "65"},
  {lang: "DE", code: "SK", label: "Slowakische Republik", phone: "421"},
  {lang: "DE", code: "SI", label: "Slowenien", phone: "386"},
  {lang: "DE", code: "SO", label: "Somalia", phone: "252"},
  {lang: "DE", code: "ES", label: "Spanien", phone: "34"},
  {lang: "DE", code: "LK", label: "Sri Lanka", phone: "94"},
  {lang: "DE", code: "BL", label: "St. Barthélemy", phone: "590"},
  {lang: "DE", code: "SH", label: "St. Helena", phone: "290"},
  {lang: "DE", code: "KN", label: "St. Kitts/Nevis", phone: "1-869"},
  {lang: "DE", code: "LC", label: "St. Lucia", phone: "1-758"},
  {lang: "DE", code: "MF", label: "St. Martin", phone: "590"},
  {lang: "DE", code: "PM", label: "St. Pierre und Miquelon", phone: "508"},
  {lang: "DE", code: "VC", label: "St. Vincent und die Grenadinen", phone: "1-784"},
  {lang: "DE", code: "SD", label: "Sudan", phone: "249"},
  {lang: "DE", code: "SR", label: "Surinam", phone: "597"},
  {lang: "DE", code: "SJ", label: "Svalbard und Jan Mayen", phone: "47"},
  {lang: "DE", code: "SZ", label: "Swasiland", phone: "268"},
  {lang: "DE", code: "SY", label: "Syrien", phone: "963"},
  {lang: "DE", code: "ST", label: "São Tomé und Príncipe", phone: "239"},
  {lang: "DE", code: "ZA", label: "Südafrika", phone: "27"},
  {lang: "DE", code: "GS", label: "Südgeorgien und Südlichen Sandwichinseln", phone: "500"},
  {lang: "DE", code: "KR", label: "Südkorea", phone: "82"},
  {lang: "DE", code: "TJ", label: "Tadschikistan", phone: "992"},
  {lang: "DE", code: "TW", label: "Taiwan", phone: "886"},
  {lang: "DE", code: "TZ", label: "Tansania", phone: "255"},
  {lang: "DE", code: "TH", label: "Thailand", phone: "66"},
  {lang: "DE", code: "TL", label: "Timor-Leste", phone: "670"},
  {lang: "DE", code: "TG", label: "Togo", phone: "228"},
  {lang: "DE", code: "TK", label: "Tokelau", phone: "690"},
  {lang: "DE", code: "TO", label: "Tonga", phone: "676"},
  {lang: "DE", code: "TT", label: "Trinidad und Tobago", phone: "1-868"},
  {lang: "DE", code: "TD", label: "Tschad", phone: "235"},
  {lang: "DE", code: "CZ", label: "Tschechoslowakei", phone: "420"},
  {lang: "DE", code: "TN", label: "Tunisien", phone: "216"},
  {lang: "DE", code: "TM", label: "Turkmenistan", phone: "993"},
  {lang: "DE", code: "TC", label: "Turks- und Caicosinseln", phone: "1-649"},
  {lang: "DE", code: "TV", label: "Tuvalu", phone: "688"},
  {lang: "DE", code: "TR", label: "Türkei", phone: "90"},
  {lang: "DE", code: "UG", label: "Uganda", phone: "256"},
  {lang: "DE", code: "UA", label: "Ukraine", phone: "380"},
  {lang: "DE", code: "HU", label: "Ungarn", phone: "36"},
  {lang: "DE", code: "UY", label: "Uruguay", phone: "598"},
  {lang: "DE", code: "US", label: "USA", phone: "1"},
  {lang: "DE", code: "UZ", label: "Usbekistan", phone: "998"},
  {lang: "DE", code: "VU", label: "Vanuatu", phone: "678"},
  {lang: "DE", code: "VA", label: "Vatikanstadt", phone: "379"},
  {lang: "DE", code: "VE", label: "Venezuela", phone: "58"},
  {lang: "DE", code: "AE", label: "Vereinigte Arabische Emirate", phone: "971"},
  {lang: "DE", code: "VN", label: "Vietnam", phone: "84"},
  {lang: "DE", code: "WF", label: "Wallis/Futuna", phone: "681"},
  {lang: "DE", code: "CX", label: "Weihnachtsinsel", phone: "61"},
  {lang: "DE", code: "BY", label: "Weißrussland", phone: "375"},
  {lang: "DE", code: "EH", label: "Westsahara", phone: "212"},
  {lang: "DE", code: "CF", label: "Zentralafrikanische Republik", phone: "236"},
  {lang: "DE", code: "CY", label: "Zypern", phone: "357"},

  // French translations
  {lang: "FR", code: "CH", label: "SUISSE", phone: "41"},
  {lang: "FR", code: "A", label: "AFGHANISTAN", phone: "93"},
  {lang: "FR", code: "ZA", label: "AFRIQUE DU SUD", phone: "27"},
  {lang: "FR", code: "AX", label: "ÅLAND, ÎLES", phone: "358"},
  {lang: "FR", code: "AL", label: "ALBANIE", phone: "355"},
  {lang: "FR", code: "DZ", label: "ALGÉRIE", phone: "213"},
  {lang: "FR", code: "D", label: "ALLEMAGNE", phone: "49"},
  {lang: "FR", code: "AD", label: "ANDORRE", phone: "376"},
  {lang: "FR", code: "AO", label: "ANGOLA", phone: "244"},
  {lang: "FR", code: "AI", label: "ANGUILLA", phone: "1-264"},
  {lang: "FR", code: "AQ", label: "ANTARCTIQUE", phone: "672"},
  {lang: "FR", code: "AG", label: "ANTIGUA-ET-BARBUDA", phone: "1-268"},
  {lang: "FR", code: "SA", label: "ARABIE SAOUDITE", phone: "966"},
  {lang: "FR", code: "AR", label: "ARGENTINE", phone: "54"},
  {lang: "FR", code: "AM", label: "ARMÉNIE", phone: "374"},
  {lang: "FR", code: "AW", label: "ARUBA", phone: "297"},
  {lang: "FR", code: "AU", label: "AUSTRALIE", phone: "61"},
  {lang: "FR", code: "AT", label: "AUTRICHE", phone: "43"},
  {lang: "FR", code: "AZ", label: "AZERBAÏDJAN", phone: "994"},
  {lang: "FR", code: "BS", label: "BAHAMAS", phone: "1-242"},
  {lang: "FR", code: "BH", label: "BAHREÏN", phone: "973"},
  {lang: "FR", code: "BD", label: "BANGLADESH", phone: "880"},
  {lang: "FR", code: "BB", label: "BARBADE", phone: "1-246"},
  {lang: "FR", code: "BY", label: "BÉLARUS", phone: "375"},
  {lang: "FR", code: "BE", label: "BELGIQUE", phone: "32"},
  {lang: "FR", code: "BZ", label: "BELIZE", phone: "501"},
  {lang: "FR", code: "BJ", label: "BÉNIN", phone: "229"},
  {lang: "FR", code: "BM", label: "BERMUDES", phone: "1-441"},
  {lang: "FR", code: "BT", label: "BHOUTAN", phone: "975"},
  {lang: "FR", code: "BO", label: "BOLIVIE, l'ÉTAT PLURINATIONAL DE", phone: "591"},
  {lang: "FR", code: "BA", label: "BOSNIE-HERZÉGOVINE", phone: "387"},
  {lang: "FR", code: "BW", label: "BOTSWANA", phone: "267"},
  {lang: "FR", code: "BV", label: "BOUVET, ÎLE", phone: "47"},
  {lang: "FR", code: "BR", label: "BRÉSIL", phone: "55"},
  {lang: "FR", code: "BN", label: "BRUNÉI DARUSSALAM", phone: "673"},
  {lang: "FR", code: "BG", label: "BULGARIE", phone: "359"},
  {lang: "FR", code: "BF", label: "BURKINA FASO", phone: "226"},
  {lang: "FR", code: "BI", label: "BURUNDI", phone: "257"},
  {lang: "FR", code: "KY", label: "CAÏMANES, ÎLES", phone: "1-345"},
  {lang: "FR", code: "KH", label: "CAMBODGE", phone: "855"},
  {lang: "FR", code: "CM", label: "CAMEROUN", phone: "237"},
  {lang: "FR", code: "CA", label: "CANADA", phone: "1"},
  {lang: "FR", code: "CV", label: "CAP-VERT", phone: "238"},
  {lang: "FR", code: "CF", label: "CENTRAFRICAINE, RÉPUBLIQUE", phone: "236"},
  {lang: "FR", code: "CL", label: "CHILI", phone: "56"},
  {lang: "FR", code: "CN", label: "CHINE", phone: "86"},
  {lang: "FR", code: "CX", label: "CHRISTMAS, ÎLE", phone: "61"},
  {lang: "FR", code: "CY", label: "CHYPRE", phone: "357"},
  {lang: "FR", code: "CC", label: "COCOS (KEELING), ÎLES", phone: "61"},
  {lang: "FR", code: "CO", label: "COLOMBIE", phone: "57"},
  {lang: "FR", code: "KM", label: "COMORES", phone: "269"},
  {lang: "FR", code: "CG", label: "CONGO", phone: "242"},
  {lang: "FR", code: "CD", label: "CONGO, LA RÉPUBLIQUE DÉMOCRATIQUE DU", phone: "243"},
  {lang: "FR", code: "CK", label: "COOK, ÎLES", phone: "682"},
  {lang: "FR", code: "KR", label: "CORÉE, RÉPUBLIQUE DE", phone: "82"},
  {lang: "FR", code: "KP", label: "CORÉE, RÉPUBLIQUE POPULAIRE DÉMOCRATIQUE DE", phone: "850"},
  {lang: "FR", code: "CR", label: "COSTA RICA", phone: "506"},
  {lang: "FR", code: "CI", label: "CÔTE D'IVOIRE", phone: "225"},
  {lang: "FR", code: "HR", label: "CROATIE", phone: "385"},
  {lang: "FR", code: "CU", label: "CUBA", phone: "53"},
  {lang: "FR", code: "DK", label: "DANEMARK", phone: "45"},
  {lang: "FR", code: "DJ", label: "DJIBOUTI", phone: "253"},
  {lang: "FR", code: "DO", label: "DOMINICAINE, RÉPUBLIQUE", phone: "1-809"},
  {lang: "FR", code: "DM", label: "DOMINIQUE", phone: "1-767"},
  {lang: "FR", code: "EG", label: "ÉGYPTE", phone: "20"},
  {lang: "FR", code: "SV", label: "EL SALVADOR", phone: "503"},
  {lang: "FR", code: "AE", label: "ÉMIRATS ARABES UNIS", phone: "971"},
  {lang: "FR", code: "EC", label: "ÉQUATEUR", phone: "593"},
  {lang: "FR", code: "ER", label: "ÉRYTHRÉE", phone: "291"},
  {lang: "FR", code: "ES", label: "ESPAGNE", phone: "34"},
  {lang: "FR", code: "EE", label: "ESTONIE", phone: "372"},
  {lang: "FR", code: "US", label: "ÉTATS-UNIS", phone: "1"},
  {lang: "FR", code: "ET", label: "ÉTHIOPIE", phone: "251"},
  {lang: "FR", code: "FK", label: "FALKLAND, ÎLES (MALVINAS)", phone: "500"},
  {lang: "FR", code: "FO", label: "FÉROÉ, ÎLES", phone: "298"},
  {lang: "FR", code: "FJ", label: "FIDJI", phone: "679"},
  {lang: "FR", code: "FI", label: "FINLANDE", phone: "358"},
  {lang: "FR", code: "FR", label: "FRANCE", phone: "33"},
  {lang: "FR", code: "GA", label: "GABON", phone: "241"},
  {lang: "FR", code: "GM", label: "GAMBIE", phone: "220"},
  {lang: "FR", code: "GE", label: "GÉORGIE", phone: "995"},
  {lang: "FR", code: "GS", label: "GÉORGIE DU SUD ET LES ÎLES SANDWICH DU SUD", phone: "500"},
  {lang: "FR", code: "GH", label: "GHANA", phone: "233"},
  {lang: "FR", code: "GI", label: "GIBRALTAR", phone: "350"},
  {lang: "FR", code: "GR", label: "GRÈCE", phone: "30"},
  {lang: "FR", code: "GD", label: "GRENADE", phone: "1-473"},
  {lang: "FR", code: "GL", label: "GROENLAND", phone: "299"},
  {lang: "FR", code: "GP", label: "GUADELOUPE", phone: "590"},
  {lang: "FR", code: "GU", label: "GUAM", phone: "1-671"},
  {lang: "FR", code: "GT", label: "GUATEMALA", phone: "502"},
  {lang: "FR", code: "GG", label: "GUERNESEY", phone: "44"},
  {lang: "FR", code: "GN", label: "GUINÉE", phone: "224"},
  {lang: "FR", code: "GW", label: "GUINÉE-BISSAU", phone: "245"},
  {lang: "FR", code: "GQ", label: "GUINÉE ÉQUATORIALE", phone: "240"},
  {lang: "FR", code: "GY", label: "GUYANA", phone: "592"},
  {lang: "FR", code: "GF", label: "GUYANE FRANÇAISE", phone: "594"},
  {lang: "FR", code: "HT", label: "HAÏTI", phone: "509"},
  {lang: "FR", code: "HM", label: "HEARD, ÎLE ET MCDONALD, ÎLES", phone: "672"},
  {lang: "FR", code: "HN", label: "HONDURAS", phone: "504"},
  {lang: "FR", code: "HK", label: "HONG-KONG", phone: "852"},
  {lang: "FR", code: "HU", label: "HONGRIE", phone: "36"},
  {lang: "FR", code: "IM", label: "ÎLE DE MAN", phone: "44"},
  {lang: "FR", code: "VG", label: "ÎLES VIERGES BRITANNIQUES", phone: "1-284"},
  {lang: "FR", code: "VI", label: "ÎLES VIERGES DES ÉTATS-UNIS", phone: "1-340"},
  {lang: "FR", code: "IN", label: "INDE", phone: "91"},
  {lang: "FR", code: "ID", label: "INDONÉSIE", phone: "62"},
  {lang: "FR", code: "IR", label: "IRAN, RÉPUBLIQUE ISLAMIQUE D'", phone: "98"},
  {lang: "FR", code: "IQ", label: "IRAQ", phone: "964"},
  {lang: "FR", code: "IE", label: "IRLANDE", phone: "353"},
  {lang: "FR", code: "IS", label: "ISLANDE", phone: "354"},
  {lang: "FR", code: "IL", label: "ISRAËL", phone: "972"},
  {lang: "FR", code: "IT", label: "ITALIE", phone: "39"},
  {lang: "FR", code: "JM", label: "JAMAÏQUE", phone: "1-876"},
  {lang: "FR", code: "JP", label: "JAPON", phone: "81"},
  {lang: "FR", code: "JE", label: "JERSEY", phone: "44"},
  {lang: "FR", code: "JO", label: "JORDANIE", phone: "962"},
  {lang: "FR", code: "KZ", label: "KAZAKHSTAN", phone: "7"},
  {lang: "FR", code: "KE", label: "KENYA", phone: "254"},
  {lang: "FR", code: "KG", label: "KIRGHIZISTAN", phone: "996"},
  {lang: "FR", code: "KI", label: "KIRIBATI", phone: "686"},
  {lang: "FR", code: "KW", label: "KOWEÏT", phone: "965"},
  {lang: "FR", code: "LA", label: "LAO, RÉPUBLIQUE DÉMOCRATIQUE POPULAIRE", phone: "856"},
  {lang: "FR", code: "LS", label: "LESOTHO", phone: "266"},
  {lang: "FR", code: "LV", label: "LETTONIE", phone: "371"},
  {lang: "FR", code: "LB", label: "LIBAN", phone: "961"},
  {lang: "FR", code: "LR", label: "LIBÉRIA", phone: "231"},
  {lang: "FR", code: "LY", label: "LIBYENNE, JAMAHIRIYA ARABE", phone: "218"},
  {lang: "FR", code: "LI", label: "LIECHTENSTEIN", phone: "423"},
  {lang: "FR", code: "LT", label: "LITUANIE", phone: "370"},
  {lang: "FR", code: "LU", label: "LUXEMBOURG", phone: "352"},
  {lang: "FR", code: "MO", label: "MACAO", phone: "853"},
  {lang: "FR", code: "MK", label: "MACÉDOINE, L'EX-RÉPUBLIQUE YOUGOSLAVE DE", phone: "389"},
  {lang: "FR", code: "MG", label: "MADAGASCAR", phone: "261"},
  {lang: "FR", code: "MY", label: "MALAISIE", phone: "60"},
  {lang: "FR", code: "MW", label: "MALAWI", phone: "265"},
  {lang: "FR", code: "MV", label: "MALDIVES", phone: "960"},
  {lang: "FR", code: "ML", label: "MALI", phone: "223"},
  {lang: "FR", code: "MT", label: "MALTE", phone: "356"},
  {lang: "FR", code: "MP", label: "MARIANNES DU NORD, ÎLES", phone: "1-670"},
  {lang: "FR", code: "MA", label: "MAROC", phone: "212"},
  {lang: "FR", code: "MH", label: "MARSHALL, ÎLES", phone: "692"},
  {lang: "FR", code: "MQ", label: "MARTINIQUE", phone: "596"},
  {lang: "FR", code: "MU", label: "MAURICE", phone: "230"},
  {lang: "FR", code: "MR", label: "MAURITANIE", phone: "222"},
  {lang: "FR", code: "YT", label: "MAYOTTE", phone: "262"},
  {lang: "FR", code: "MX", label: "MEXIQUE", phone: "52"},
  {lang: "FR", code: "FM", label: "MICRONÉSIE, ÉTATS FÉDÉRÉS DE", phone: "691"},
  {lang: "FR", code: "MD", label: "MOLDOVA, RÉPUBLIQUE DE", phone: "373"},
  {lang: "FR", code: "MC", label: "MONACO", phone: "377"},
  {lang: "FR", code: "MN", label: "MONGOLIE", phone: "976"},
  {lang: "FR", code: "ME", label: "MONTÉNÉGRO", phone: "382"},
  {lang: "FR", code: "MS", label: "MONTSERRAT", phone: "1-664"},
  {lang: "FR", code: "MZ", label: "MOZAMBIQUE", phone: "258"},
  {lang: "FR", code: "MM", label: "MYANMAR", phone: "95"},
  {lang: "FR", code: "NA", label: "NAMIBIE", phone: "264"},
  {lang: "FR", code: "NR", label: "NAURU", phone: "674"},
  {lang: "FR", code: "NP", label: "NÉPAL", phone: "977"},
  {lang: "FR", code: "NI", label: "NICARAGUA", phone: "505"},
  {lang: "FR", code: "NE", label: "NIGER", phone: "227"},
  {lang: "FR", code: "NG", label: "NIGÉRIA", phone: "234"},
  {lang: "FR", code: "NU", label: "NIUÉ", phone: "683"},
  {lang: "FR", code: "NF", label: "NORFOLK, ÎLE", phone: "672"},
  {lang: "FR", code: "NO", label: "NORVÈGE", phone: "47"},
  {lang: "FR", code: "NC", label: "NOUVELLE-CALÉDONIE", phone: "687"},
  {lang: "FR", code: "NZ", label: "NOUVELLE-ZÉLANDE", phone: "64"},
  {lang: "FR", code: "IO", label: "OCÉAN INDIEN, TERRITOIRE BRITANNIQUE DE L'", phone: "246"},
  {lang: "FR", code: "OM", label: "OMAN", phone: "968"},
  {lang: "FR", code: "UG", label: "OUGANDA", phone: "256"},
  {lang: "FR", code: "UZ", label: "OUZBÉKISTAN", phone: "998"},
  {lang: "FR", code: "PK", label: "PAKISTAN", phone: "92"},
  {lang: "FR", code: "PW", label: "PALAOS", phone: "680"},
  {lang: "FR", code: "PS", label: "PALESTINIEN OCCUPÉ, TERRITOIRE", phone: "970"},
  {lang: "FR", code: "PA", label: "PANAMA", phone: "507"},
  {lang: "FR", code: "PG", label: "PAPOUASIE-NOUVELLE-GUINÉE", phone: "675"},
  {lang: "FR", code: "PY", label: "PARAGUAY", phone: "595"},
  {lang: "FR", code: "NL", label: "PAYS-BAS", phone: "31"},
  {lang: "FR", code: "PE", label: "PÉROU", phone: "51"},
  {lang: "FR", code: "PH", label: "PHILIPPINES", phone: "63"},
  {lang: "FR", code: "PN", label: "PITCAIRN", phone: "870"},
  {lang: "FR", code: "PL", label: "POLOGNE", phone: "48"},
  {lang: "FR", code: "PF", label: "POLYNÉSIE FRANÇAISE", phone: "689"},
  {lang: "FR", code: "PR", label: "PORTO RICO", phone: "1"},
  {lang: "FR", code: "PT", label: "PORTUGAL", phone: "351"},
  {lang: "FR", code: "QA", label: "QATAR", phone: "974"},
  {lang: "FR", code: "RE", label: "RÉUNION", phone: "262"},
  {lang: "FR", code: "RO", label: "ROUMANIE", phone: "40"},
  {lang: "FR", code: "GB", label: "ROYAUME-UNI", phone: "44"},
  {lang: "FR", code: "RU", label: "RUSSIE, FÉDÉRATION DE", phone: "7"},
  {lang: "FR", code: "RW", label: "RWANDA", phone: "250"},
  {lang: "FR", code: "EH", label: "SAHARA OCCIDENTAL", phone: "212"},
  {lang: "FR", code: "BL", label: "SAINT-BARTHÉLEMY", phone: "590"},
  {lang: "FR", code: "SH", label: "SAINTE-HÉLÈNE, ASCENSION ET TRISTAN DA CUNHA", phone: "290"},
  {lang: "FR", code: "LC", label: "SAINTE-LUCIE", phone: "1-758"},
  {lang: "FR", code: "KN", label: "SAINT-KITTS-ET-NEVIS", phone: "1-869"},
  {lang: "FR", code: "SM", label: "SAINT-MARIN", phone: "378"},
  {lang: "FR", code: "MF", label: "SAINT-MARTIN", phone: "590"},
  {lang: "FR", code: "PM", label: "SAINT-PIERRE-ET-MIQUELON", phone: "508"},
  {lang: "FR", code: "VA", label: "SAINT-SIÈGE (ÉTAT DE LA CITÉ DU VATICAN)", phone: "379"},
  {lang: "FR", code: "VC", label: "SAINT-VINCENT-ET-LES GRENADINES", phone: "1-784"},
  {lang: "FR", code: "SB", label: "SALOMON, ÎLES", phone: "677"},
  {lang: "FR", code: "WS", label: "SAMOA", phone: "685"},
  {lang: "FR", code: "AS", label: "SAMOA AMÉRICAINES", phone: "1-684"},
  {lang: "FR", code: "ST", label: "SAO TOMÉ-ET-PRINCIPE", phone: "239"},
  {lang: "FR", code: "SN", label: "SÉNÉGAL", phone: "221"},
  {lang: "FR", code: "RS", label: "SERBIE", phone: "381"},
  {lang: "FR", code: "SC", label: "SEYCHELLES", phone: "248"},
  {lang: "FR", code: "SL", label: "SIERRA LEONE", phone: "232"},
  {lang: "FR", code: "SG", label: "SINGAPOUR", phone: "65"},
  {lang: "FR", code: "SK", label: "SLOVAQUIE", phone: "421"},
  {lang: "FR", code: "SI", label: "SLOVÉNIE", phone: "386"},
  {lang: "FR", code: "SO", label: "SOMALIE", phone: "252"},
  {lang: "FR", code: "SD", label: "SOUDAN", phone: "249"},
  {lang: "FR", code: "LK", label: "SRI LANKA", phone: "94"},
  {lang: "FR", code: "SE", label: "SUÈDE", phone: "46"},
  {lang: "FR", code: "SR", label: "SURINAME", phone: "597"},
  {lang: "FR", code: "SJ", label: "SVALBARD ET ÎLE JAN MAYEN", phone: "47"},
  {lang: "FR", code: "SZ", label: "SWAZILAND", phone: "268"},
  {lang: "FR", code: "SY", label: "SYRIENNE, RÉPUBLIQUE ARABE", phone: "963"},
  {lang: "FR", code: "TJ", label: "TADJIKISTAN", phone: "992"},
  {lang: "FR", code: "TW", label: "TAÏWAN, PROVINCE DE CHINE", phone: "886"},
  {lang: "FR", code: "TZ", label: "TANZANIE, RÉPUBLIQUE-UNIE DE", phone: "255"},
  {lang: "FR", code: "TD", label: "TCHAD", phone: "235"},
  {lang: "FR", code: "CZ", label: "TCHÈQUE, RÉPUBLIQUE", phone: "420"},
  {lang: "FR", code: "TF", label: "TERRES AUSTRALES FRANÇAISES", phone: "262"},
  {lang: "FR", code: "TH", label: "THAÏLANDE", phone: "66"},
  {lang: "FR", code: "TL", label: "TIMOR-LESTE", phone: "670"},
  {lang: "FR", code: "TG", label: "TOGO", phone: "228"},
  {lang: "FR", code: "TK", label: "TOKELAU", phone: "690"},
  {lang: "FR", code: "TO", label: "TONGA", phone: "676"},
  {lang: "FR", code: "TT", label: "TRINITÉ-ET-TOBAGO", phone: "1-868"},
  {lang: "FR", code: "TN", label: "TUNISIE", phone: "216"},
  {lang: "FR", code: "TM", label: "TURKMÉNISTAN", phone: "993"},
  {lang: "FR", code: "TC", label: "TURKS ET CAÏQUES, ÎLES", phone: "1-649"},
  {lang: "FR", code: "TR", label: "TURQUIE", phone: "90"},
  {lang: "FR", code: "TV", label: "TUVALU", phone: "688"},
  {lang: "FR", code: "UA", label: "UKRAINE", phone: "380"},
  {lang: "FR", code: "UY", label: "URUGUAY", phone: "598"},
  {lang: "FR", code: "VU", label: "VANUATU", phone: "678"},
  {lang: "FR", code: "VE", label: "VENEZUELA, RÉPUBLIQUE BOLIVARIENNE DU", phone: "58"},
  {lang: "FR", code: "VN", label: "VIET NAM", phone: "84"},
  {lang: "FR", code: "WF", label: "WALLIS ET FUTUNA", phone: "681"},
  {lang: "FR", code: "YE", label: "YÉMEN", phone: "967"},
  {lang: "FR", code: "ZM", label: "ZAMBIE", phone: "260"},
  {lang: "FR", code: "ZW", label: "ZIMBABWE", phone: "263"},
];
