import React, {useEffect, useState} from "react";
import {
  Grid,
  Card,
  Stepper,
  Step,
  StepLabel,
  CardActions,
  Toolbar,
  Button,
} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {PatientRegistrationForm} from "./Steps/PatientRegistrationForm";
import {MedicalInformationForm} from "./Steps/MedicalInformationForm";
import {BookingForm} from "./Steps/BookingForm";

import {
  selectBookingFields,
  selectMedicalInformationFields,
  selectPatientFields,
  postBooking,
  clearPatientFormFields,
  getBooking,
  editBooking,
  selectLoadingPatientForm,
  getAvailability,
  getInsuranceCompanies,
  changeBookingFieldValue,
} from "./patientFormSlice";
import {UseAppSelector, useAnyDispatch} from "store/hooks";
import Backdrop from "components/form/Backdrop";


export function PatientForm() {
  const {t} = useTranslation();
  const dispatch=useAnyDispatch();
  const navigate=useNavigate();

  const bookingFields=UseAppSelector(selectBookingFields);
  const patientFields=UseAppSelector(selectPatientFields);
  const medicalInformationFields=UseAppSelector(selectMedicalInformationFields);
  const loading=UseAppSelector(selectLoadingPatientForm);
  const {id}=useParams();

  useEffect(()=>{
    dispatch(clearPatientFormFields());
    if (id) {
      dispatch(getBooking(id));
    }
    dispatch(getInsuranceCompanies());
  }, []);

  useEffect(()=>{
    if (bookingFields.check_in_date&&bookingFields.check_out_date) {
      dispatch(getAvailability({checkIn: bookingFields.check_in_date, checkOut: bookingFields.check_out_date}));
      !id&&dispatch(changeBookingFieldValue({field: "appartment_id", value: ""}));
    }
  }, [bookingFields.check_in_date, bookingFields.check_out_date]);


  // Stepper Variables
  const [stepperPage, setStepperPage]=useState(0);
  const steps=["patientData", "medication", "roomBooking"]
      .map((e, idx)=>({
        key: t(`entry.step.${e}`),
        title: window.innerWidth>768|| stepperPage===idx? t(`entry.step.${e}`):"",
        onClick: () => setStepperPage(idx),
      }));
  const displayProp=(pageNumber:typeof stepperPage)=>({display: stepperPage===pageNumber?"flex" as "flex":"none" as "none"});

  const handleSaveBooking=async ()=>{
    const booking={...bookingFields, patient: {...patientFields}, medical_information: medicalInformationFields};

    id?dispatch(editBooking({booking_id: id, bookingEditData: booking}))
        .then(()=>{
          navigate("/patients", {replace: true});
        }):
    dispatch(postBooking(booking))
        .then(()=>{
          navigate("/patients", {replace: true});
        });
  };

  return (
    <Card sx={{width: "100%", padding: 1}}>
      <Backdrop open={Object.values(loading).includes("pending")}/>
      <Grid container>
        <Grid item xs={12}>
          <Stepper nonLinear activeStep={stepperPage} alternativeLabel>
            {steps.map((e, index) =><Step key={e.key} onClick={e.onClick}><StepLabel >{e.title}</StepLabel></Step>)}
          </Stepper>
          <Grid item xs={12} container {...displayProp(0)}>
            <PatientRegistrationForm/>
          </Grid>
          <Grid item xs={12} container {...displayProp(1)}>
            <MedicalInformationForm/>
          </Grid>
          <Grid item xs={12} container {...displayProp(2)}>
            <BookingForm/>
          </Grid>


        </Grid>
        <CardActions sx={{width: "100%"}}>
          <Grid item xs={12}>
            <Toolbar sx={{justifyContent: "space-between", flexDirection: "row-reverse"}}>
              {stepperPage===2&&<Button data-cy="saveButton" variant="contained" onClick={handleSaveBooking}>{t("form.button.save")}</Button>}
              {stepperPage!==2&&<Button data-cy="nextButton" variant="contained" onClick={()=>setStepperPage(stepperPage+1)}>{t("form.button.next")}</Button>}
              {stepperPage!==0&&<Button variant="text" onClick={()=>setStepperPage(stepperPage-1)}>{t("form.button.prev")}</Button>}
            </Toolbar>
          </Grid>
        </CardActions>
      </Grid>
    </Card>
  );
}
