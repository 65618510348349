import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {displayToastError, displayToastSuccess} from "features/ErrorBoundary/errorBoundarySlice";
import {RootState} from "store/store";
import {get} from "../../utils/api";
import {sensorListType} from "./types";


export const fetchSensors = createAsyncThunk(
    "sensors/fetchSensors",
    async (page:number, {rejectWithValue, dispatch}) => {
      const response = await get(`home4/sensors?page=${page}&limit=5`);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("SENSORS.FETCH.FAILED"));
        return rejectWithValue("Cannot fetch sensors");
      } else {
        dispatch(displayToastSuccess("SENSORS.FETCH.SUCCESS"));
        return response;
      }
    },
);
export const deleteSensor= createAsyncThunk(
    "sensors/deleteSensor",
    async (sensor_id:number, {rejectWithValue, dispatch}) => {
      const response = await get("home4/sensors/delete/"+sensor_id);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("SENSORS.DELETE.FAILED"));
        return rejectWithValue("Cannot delete sensor");
      } else {
        dispatch(displayToastSuccess("SENSORS.DELETE.SUCCESS"));
        return response;
      }
    },
);

export const getDataForCsv= createAsyncThunk(
    "sensors/getDataForCsv",
    async (sensor_id:number, {dispatch, rejectWithValue}) => {
      const response = await get("home4/sensors/data?sensor_id="+sensor_id);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("SENSOR.SAVE.CSV.FAILED"));
        return rejectWithValue("Cannot save sensor as CSV");
      } else if (response.result.length===0) {
        dispatch(displayToastError("NO.DATA.TO.SAVE"));
        return rejectWithValue("No data for CSV");
      } else {
        dispatch(displayToastSuccess("SENSOR.SAVE.CSV.SUCCESS"));
        return response;
      }
    },
);


export interface sensorListState {
  sensors:sensorListType[],
  loading:{
    fetchSensors:string,
    deleteSensor:string,
    getDataForCsv:string,
  },
  error: any,
}

const initialState:sensorListState={
  sensors: [],
  loading: {
    fetchSensors: "idle",
    deleteSensor: "idle",
    getDataForCsv: "idle",
  },
  error: {},
};
export const sensorListSlice = createSlice({
  name: "sensors",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(fetchSensors.pending, (state, action) => {
          if (state.loading.fetchSensors === "idle") {
            state.loading.fetchSensors = "pending";
          }
        })
        .addCase(fetchSensors.fulfilled, (state, action) => {
          if (
            state.loading.fetchSensors === "pending"
          ) {
            state.loading.fetchSensors = "idle";
            state.sensors=action.payload.result;
          }
        })
        .addCase(fetchSensors.rejected, (state, action) => {
          if (
            state.loading.fetchSensors === "pending"
          ) {
            state.loading.fetchSensors = "idle";
            state.sensors=[];
            state.error = action.error;
          }
        })
        .addCase(deleteSensor.pending, (state, action) => {
          if (state.loading.deleteSensor === "idle") {
            state.loading.deleteSensor = "pending";
          }
        })
        .addCase(deleteSensor.fulfilled, (state, action) => {
          if (
            state.loading.deleteSensor === "pending"
          ) {
            state.loading.deleteSensor = "idle";
            state.sensors=state.sensors.filter((sensor)=>sensor.sensor_id!==action.payload.result.deleted_id);
          }
        })
        .addCase(deleteSensor.rejected, (state, action) => {
          if (
            state.loading.deleteSensor === "pending"
          ) {
            state.loading.deleteSensor = "idle";
          }
        })
        .addCase(getDataForCsv.pending, (state, action) => {
          if (state.loading.getDataForCsv === "idle") {
            state.loading.getDataForCsv = "pending";
          }
        })
        .addCase(getDataForCsv.fulfilled, (state, action) => {
          if (
            state.loading.getDataForCsv === "pending"
          ) {
            state.loading.getDataForCsv = "idle";
          }
        })
        .addCase(getDataForCsv.rejected, (state, action) => {
          if (
            state.loading.getDataForCsv === "pending"
          ) {
            state.loading.getDataForCsv = "idle";
          }
        });
  },
});

export const selectSensors=(state:RootState)=>state.sensorList.sensors;
export const selectLoadingSensorList = (state: RootState) => state.sensorList.loading;


export default sensorListSlice.reducer;
