import React from "react";
import {useTranslation} from "react-i18next";

import {
  AppBar,
  Toolbar,
  Container,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import {LinkedIn} from "utils/icons";

export const Footer = ()=>{
  const {t} = useTranslation();

  return (<AppBar position="static" color="inherit" sx={{top: "auto", bottom: 0}}>
    <Container maxWidth="lg" >
      <Box flexDirection="row">
        <Toolbar variant="dense">
          <Button href="https://storage.patient-strength.ch/b2c/PatientStrength_Anleitung_KoGu_v3de.pdf"
            style={{textTransform: "none", color: "#23282c"}}
            target="_blank" size="small" >{t("link.usermanual")}
          </Button>
          <Button href="#/imprint"
            data-cy="imprintButton"
            style={{textTransform: "none", color: "#23282c"}} size="small">{t("imprint.title")}
          </Button>
          <Box sx={{flexGrow: 1}} />
          <IconButton href="https://www.linkedin.com/company/patient-strength" size="small" target="_blank" >
            <LinkedIn />
          </IconButton >
        </Toolbar>
      </Box>
    </Container>
  </AppBar>);
};
