import React from "react";
import {useTranslation} from "react-i18next";
// eslint-disable-next-line no-unused-vars
import {Routes, Route, Navigate} from "react-router-dom";
import {routes} from "utils/routes";

export const Content=()=>{
  const {i18n} = useTranslation();
  return <Routes>
    {routes(i18n)
        .map((route, idx) =>
          <Route
            key={idx}
            path={route.path}
            element={route.view}
          />,
        )}
    {/* <Navigate to="/" /> */}
  </Routes>;
};
