import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {get} from "../../utils/api";
import {RootState} from "store/store";
import {appartmentListType} from "./types";
import {displayToastError, displayToastSuccess} from "features/ErrorBoundary/errorBoundarySlice";

export const fetchAppartments = createAsyncThunk(
    "appartments/fetchAppartments",
    async (page:number, {rejectWithValue, dispatch}) => {
      const response = await get(`home4/appartments?page=${page}&limit=5`);
      // model error handling
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("APPARTMENTS.FETCH.FAILED"));
        return rejectWithValue("Cannot fetch appartments");
      } else {
        dispatch(displayToastSuccess("APPARTMENTS.FETCH.SUCCESS"));
        return response;
      }
    },
);
export const fetchAllAppartments = createAsyncThunk(
    "appartments/fetchAllAppartments",
    async (arg, {rejectWithValue, dispatch}) => {
      const response = await get("home4/appartments");
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("APPARTMENTS.FETCH.FAILED"));
        return rejectWithValue("Cannot fetch all appartments");
      } else {
        dispatch(displayToastSuccess("APPARTMENTS.FETCH.SUCCESS"));
        return response;
      }
    },
);

export const deleteAppartment = createAsyncThunk(
    "appartments/deleteAppartment",
    async (appartment_id:number, {rejectWithValue, dispatch}) => {
      const response = await get("home4/appartments/delete/"+appartment_id);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("APPARTMENT.DELETE.FAILED"));
        return rejectWithValue("Cannot delete appartment");
      } else {
        dispatch(displayToastSuccess("APPARTMENT.DELETE.SUCCESS"));
        return response;
      }
    },
);


export const getAppartmentDetails = createAsyncThunk(
    "appartments/getDetails",
    async (appartment_id:number, {rejectWithValue, dispatch}) => {
      const response = await get("home4/appartments/details/"+appartment_id);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("APPARTMENT.GET.DETAILS.FAILED"));
        return rejectWithValue("Cannot get appartment details");
      } else {
        return response;
      }
    },
);
export const getDataForCsv= createAsyncThunk(
    "appartments/getDataForCsv",
    async (appartment_id:number, {rejectWithValue, dispatch}) => {
      const response = await get("home4/sensors/data?appartment_id="+appartment_id);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("SENSOR.GET.CSV.FAILED"));
        return rejectWithValue("Cannot get appartment details");
      } else if (response.result.length===0) {
        dispatch(displayToastError("NO.DATA.TO.SAVE"));
        return rejectWithValue("No data for CSV");
      } else {
        dispatch(displayToastSuccess("SENSOR.GET.CSV.SUCCESS"));
        return response;
      }
    },
);

export interface apartmentListState {
   appartments:appartmentListType[],
    loading: {
      fetchAppartments:string,
      deleteAppartment:string,
      fetchAllAppartments:string,
      getAppartmentDetails:string,
      getDataForCsv:string,
    }
    error: any,
  }

const initialState: apartmentListState = {
  appartments: [],
  loading: {
    fetchAppartments: "idle",
    deleteAppartment: "idle",
    fetchAllAppartments: "idle",
    getAppartmentDetails: "idle",
    getDataForCsv: "idle",
  },
  error: {},
};
export const appartmentSlice = createSlice({
  name: "appartments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(fetchAppartments.pending, (state, action) => {
          if (state.loading.fetchAppartments === "idle") {
            state.loading.fetchAppartments = "pending";
          }
        })
        .addCase(fetchAppartments.fulfilled, (state, action) => {
          if (
            state.loading.fetchAppartments === "pending"
          ) {
            state.loading.fetchAppartments = "idle";
            state.appartments=action.payload?.result;
          }
        })
        .addCase(fetchAppartments.rejected, (state, action) => {
          if (
            state.loading.fetchAppartments === "pending"
          ) {
            state.loading.fetchAppartments = "idle";
            state.error = action.payload;
            state.appartments=[];
          }
        })
        .addCase(deleteAppartment.pending, (state, action) => {
          if (state.loading.deleteAppartment === "idle") {
            state.loading.deleteAppartment = "pending";
          }
        })
        .addCase(deleteAppartment.fulfilled, (state, action) => {
          if (
            state.loading.deleteAppartment === "pending"
          ) {
            state.loading.deleteAppartment = "idle";
            state.appartments=state.appartments.filter((appartment)=>appartment.appartment_id!==action.payload.result.deleted_id);
          }
          if (!action.payload.success) {
            state.error = action.payload.message;
          }
        })
        .addCase(deleteAppartment.rejected, (state, action) => {
          if (
            state.loading.deleteAppartment === "pending"
          ) {
            state.loading.deleteAppartment = "idle";
          }
        })
        .addCase(fetchAllAppartments.pending, (state, action) => {
          if (state.loading.fetchAllAppartments === "idle") {
            state.loading.fetchAllAppartments = "pending";
          }
        })
        .addCase(fetchAllAppartments.fulfilled, (state, action) => {
          if (
            state.loading.fetchAllAppartments === "pending"
          ) {
            state.loading.fetchAllAppartments = "idle";
            state.appartments=action.payload.result;
          }
          if (!action.payload.success) {
            state.error = action.payload.message;
          }
        })
        .addCase(fetchAllAppartments.rejected, (state, action) => {
          if (
            state.loading.fetchAllAppartments=== "pending"
          ) {
            state.loading.fetchAllAppartments = "idle";
            state.error = action.payload;
          }
        })
        .addCase(getAppartmentDetails.pending, (state, action) => {
          if (state.loading.getAppartmentDetails === "idle") {
            state.loading.getAppartmentDetails = "pending";
          }
        })
        .addCase(getAppartmentDetails.fulfilled, (state, action) => {
          if (
            state.loading.getAppartmentDetails === "pending"
          ) {
            state.loading.getAppartmentDetails = "idle";
            state.appartments.filter((appartment)=>
              appartment.appartment_id===action.payload?.result?.appartment_id)[0].appartment_details=action.payload?.result;
          }
          if (!action.payload.success) {
            state.error = action.payload.message;
          }
        })
        .addCase(getAppartmentDetails.rejected, (state, action) => {
          if (
            state.loading.getAppartmentDetails === "pending"
          ) {
            state.loading.getAppartmentDetails = "idle";
            state.error = action.payload;
          }
        })
        .addCase(getDataForCsv.pending, (state, action) => {
          if (state.loading.getDataForCsv === "idle") {
            state.loading.getDataForCsv = "pending";
          }
        })
        .addCase(getDataForCsv.fulfilled, (state, action) => {
          if (
            state.loading.getDataForCsv === "pending"
          ) {
            state.loading.getDataForCsv = "idle";
          }
        })
        .addCase(getDataForCsv.rejected, (state, action) => {
          if (
            state.loading.getDataForCsv === "pending"
          ) {
            state.loading.getDataForCsv = "idle";
          }
        });
  },
});
export const selectAppartments = (state: RootState) => state.appartmentList.appartments;
export const selectLoadingAppartmentList = (state: RootState) => state.appartmentList.loading;


export default appartmentSlice.reducer;
