import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import {FaInfo} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {UseAppSelector, UseAppDispatch} from "store/hooks";
import {
  selectMedicalInformationFields,
  changeMedicalInformationFieldValue,
  selectErrorFields,
  getDiagnosis,
  selectFieldLoadingPatientForm,
  selectDiagnostics,
} from "../patientFormSlice";
import RadioButtonGroupComponent from "components/form/RadioButtonGroupComponent";
import {diagnosisType} from "../types";


export function MedicalInformationForm() {
  const {t, i18n} = useTranslation();
  const dispatch = UseAppDispatch();

  const medicalInformationFieldsStoreValues = UseAppSelector(selectMedicalInformationFields);
  const errorData=UseAppSelector(selectErrorFields);
  const fieldLoading=UseAppSelector(selectFieldLoadingPatientForm);
  const diagnostics=UseAppSelector(selectDiagnostics);


  return (
    <Card sx={{width: "100%", padding: 3}}>
      <CardHeader
        className="p-0 pb-3"
        avatar={
          <Avatar aria-label="recipe" style={{
            color: "#63c2de",
            backgroundColor: "#f0f3f5",
          }}>
            <FaInfo data-toggle="popover" data-content="Disabled popover" />
          </Avatar>
        }
        title={t("entry.step.medication")}
      />
      <Grid container spacing={3} paddingBottom={3}>
        <Grid item xs={12}>
          <FormControl fullWidth data-cy={"locomotionHelp"}>
            <InputLabel >{t("patient.locomotion.help")}</InputLabel>
            <Select
              value={medicalInformationFieldsStoreValues.locomotion_help}
              error={errorData.locomotion_help}
              label={t("medicalInformation.locomotionHelp")}
              onChange={(event)=>dispatch(changeMedicalInformationFieldValue({field: "locomotion_help", value: event?.target.value}))}
            >
              <MenuItem value='Wheelchair'>{t("patient.locomotionHelp.wheelchair")}</MenuItem>
              <MenuItem value='Rollator'>{t("patient.locomotionHelp.rollator")}</MenuItem>
              <MenuItem value='Walking Stick'>{t("patient.locomotionHelp.walking.stick")}</MenuItem>
              <MenuItem value='Walking Sticks'>{t("patient.locomotionHelp.walking.sticks")}</MenuItem>
              <MenuItem value='Orthese'>{t("patient.locomotionHelp.orthese")}</MenuItem>
              <MenuItem value='Prostheses'>{t("patient.locomotionHelp.prostheses")}</MenuItem>
              <MenuItem value='None'>{t("patient.locomotionHelp.none")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={(event)=>dispatch(changeMedicalInformationFieldValue({field: "tools_comments", value: event?.target.value}))}
            value={medicalInformationFieldsStoreValues.tools_comments}
            error={errorData.tools_comments}
            inputProps={{"data-cy": "toolsComment"}}
            label= {t("medicalInformation.toolsComments")}
            fullWidth
            variant="standard" />
        </Grid>
        <Grid item xs={12}>

          <TextField
            onChange={(event)=>dispatch(changeMedicalInformationFieldValue({field: "test_leaving_reason", value: event?.target.value}))}
            value={medicalInformationFieldsStoreValues.test_leaving_reason}
            error={errorData.test_leaving_reason}
            inputProps={{"data-cy": "leavigReason"}}
            label= {t("medicalInformation.testLeavingReason")}
            fullWidth
            variant="standard" />

        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id='diagnosis'
            options={diagnostics??[]}
            onChange={(event, value)=>{
              dispatch(changeMedicalInformationFieldValue({field: "diagnosis", value: value}));
            }}
            getOptionLabel={(option)=>option&&`${option.code} ${option.description}`}
            value={medicalInformationFieldsStoreValues.diagnosis as diagnosisType}
            onInputChange={(_, newInputValue) => {
              if (newInputValue.length>=3) {
                dispatch(getDiagnosis({searchString: newInputValue, lang: i18n.language.toUpperCase()}));
              }
            }}
            renderInput={(params) =>{
              return <span>
                <CircularProgress sx={{display: fieldLoading.getDiagnosis==="idle"?"none":"block"}} color="inherit" size={20} />
                <TextField {...params} label={t("patient.diagnosis")} variant="standard"/>
              </span>;
            }}/>
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={(event)=>dispatch(changeMedicalInformationFieldValue({field: "allergies", value: event?.target.value}))}
            value={medicalInformationFieldsStoreValues.allergies}
            error={errorData.allergies}
            inputProps={{"data-cy": "allergies"}}
            label= {t("patient.allergies")}
            fullWidth
            variant="standard" />
        </Grid>
        <Grid item xs={12}>
          <RadioButtonGroupComponent
            label={t("bodyFunctions.damage")}
            dataCy='bodyFunctions'
            value={medicalInformationFieldsStoreValues.body_functions_damage}
            onChange={(event:any)=>dispatch(changeMedicalInformationFieldValue({field: "body_functions_damage", value: event}))}
            options={["no.damage", "leight.damage", "massive.damage", "significant.damage", "full.damage", "not.specified", "not.applicable"]}
            error={false}/>
        </Grid>
        <Grid item xs={12}>
          <RadioButtonGroupComponent
            label={t("bodyStructure.damage")}
            dataCy='bodyStructure'
            value={medicalInformationFieldsStoreValues.body_structure_damage}
            onChange={(event:any)=>dispatch(changeMedicalInformationFieldValue({field: "body_structure_damage", value: event}))}
            options={["no.damage", "leight.damage", "massive.damage", "significant.damage", "full.damage", "not.specified", "not.applicable"]}
            error={false}/>
        </Grid>
      </Grid>
      <Grid item xs={12} paddingBottom={3}>
        <RadioButtonGroupComponent
          label={t("participation.impairment")}
          dataCy='impairment'
          value={medicalInformationFieldsStoreValues.participation_impairment}
          onChange={(event:any)=>dispatch(changeMedicalInformationFieldValue({field: "participation_impairment", value: event}))}
          options={["no.impairment", "leight.impairment", "massive.impairment", "significant.impairment", "full.impairment", "not.specified", "not.applicable"]}
          error={false}/>
      </Grid>
      <Grid item xs={12}>
        <RadioButtonGroupComponent
          label={t("patient.challenge.factor")}
          dataCy='challengeFactor'
          value={medicalInformationFieldsStoreValues.challenge_factor}
          onChange={(event:any)=>dispatch(changeMedicalInformationFieldValue({field: "challenge_factor", value: event}))}
          options={["demandFactor.fully.developed", "demandFactor.significantly.developed", "demandFactor.massively.developed",
            "demandFactor.leightly.developed", "demandFactor.not.developed", "barrier.leightly.developed", "barrier.massively.developed", "barrier.significantly.developed",
            "barrier.fully.developed", "not.specified", "not.applicable"]}
          error={false}/>

      </Grid>
    </Card>
  );
}
