import {Slide, Snackbar, Alert, AlertColor, ClickAwayListener} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {connect, ConnectedProps} from "react-redux";
import {UseAppDispatch} from "store/hooks";
import {RootState} from "store/store";

import {setSnackbarStatus} from "features/ErrorBoundary/errorBoundarySlice";

const ErrorBoundary = ({
  openMessage,
  feedbackType,
  feedbackMessage,
}:Props) => {
  const dispatch = UseAppDispatch();
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) =>
    reason !== "clickaway"&&dispatch(setSnackbarStatus(false));
  const {t} = useTranslation();
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClose}
    >
      <Snackbar
        open={openMessage}
        onClose={handleClose}
        anchorOrigin={{vertical: "top", horizontal: "center"}}
        autoHideDuration={5000}
        sx={{display: openMessage?"block":"none"}}
        hidden={!openMessage}
        TransitionComponent={(props)=> <Slide {...props} direction="down" />}
      >
        <Alert
          severity={feedbackType||"info"}>
          {t(feedbackMessage)}
        </Alert>
      </Snackbar>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state:RootState) => ({
  openMessage: state.snackbar.openMessage,
  feedbackType: state.snackbar.feedbackType,
  feedbackMessage: state.snackbar.feedbackMessage,
});

const connector = connect(mapStateToProps);

interface Props extends PropsFromRedux {
  openMessage:boolean
  feedbackType:AlertColor
  feedbackMessage:string
}

type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ErrorBoundary);
