export const fieldsInfo={
  name: {
    page: "residence",
    initialValue: "",
    validate: ()=>false,
  },
  street: {
    page: "residence",
    initialValue: "",
    validate: ()=>false,
  },
  post_code: {
    initialValue: "",
    validate: (value)=>value<999,
    page: "residence",
  },
  location: {
    page: "residence",
    initialValue: "",
    validate: ()=>false,
  },
  country: {
    page: "residence",
    initialValue: "",
    validate: ()=>false,
  },
  floor: {
    page: "residence",
    initialValue: "",
    validate: ()=>false,
  },
  wheelchairAccessible: {
    page: "residence",
    initialValue: "",
    validate: ()=>false,
  },
  information_for_doctors: {
    page: "residence",
    initialValue: "",
    validate: ()=>false,

  },
  contactmail: {
    page: "residence",
    initialValue: "",
    validate: ()=>false,
  },
  remarks: {
    page: "residence",
    initialValue: "",
    validate: ()=>false,
  },
} as
{[key:string]:{
  page:string
  initialValue:string
  validate: (value:any)=>boolean
}};
