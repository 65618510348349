import {ApartmentForm} from "features/ApartmentForm/ApartmentForm";
import {PatientForm} from "features/PatientForm/PatientForm";
import {SensorList} from "features/SensorList/SensorList";
import {AppartmentsList} from "features/AppartmentList/AppartmentList";
import React from "react";

// import i18n from "./i18n";
import {FaHome, MonitorWeight, FaRegUser} from "utils/icons";
import {i18n} from "i18next";
import {BookingsList} from "features/BookingList/BookingList";
import {SensorForm} from "features/SensorForm/SensorForm";
import HomePage from "views/HomePage";

export const routes=(i18n: i18n)=>[
  {
    path: "/",
    linkInHeader: true,
    label: i18n.t("header.overview"),
    view: <HomePage/>,
    isHomeButton: false,
  },
  {
    path: "/newApartment",
    linkInHeader: true,
    label: i18n.t("header.new.apartment"),
    view: <ApartmentForm/>,
    type: "new",
    isHomeButton: true,
    icon: <FaHome/>,
  },
  {
    path: "/editAppartment/:id",
    linkInHeader: false,
    label: i18n.t("header.new.apartment"),
    view: <ApartmentForm/>,
    isHomeButton: false,
    icon: <FaHome/>,
  },

  {
    path: "/newSensor",
    linkInHeader: true,
    label: i18n.t("header.new.sensor"),
    view: <SensorForm/>,
    type: "new",
    isHomeButton: true,
    icon: <MonitorWeight/>,
  },
  {
    path: "/editSensor/:id",
    linkInHeader: false,
    label: i18n.t("header.new.sensor"),
    view: <SensorForm/>,
    isHomeButton: false,
    icon: <MonitorWeight/>,
  },
  {
    path: "/newPatient",
    linkInHeader: true,
    label: i18n.t("header.new.patient"),
    view: <PatientForm/>,
    type: "new",
    isHomeButton: true,
    icon: <FaRegUser/>,
  },
  {
    path: "/editPatient/:id",
    linkInHeader: false,
    label: i18n.t("header.new.patient"),
    view: <PatientForm/>,
    isHomeButton: false,
    icon: <FaRegUser/>,
  },
  {
    path: "/apartments",
    linkInHeader: true,
    type: "list",
    label: i18n.t("header.list.myapartments"),
    view: <AppartmentsList/>,
    isHomeButton: true,
    icon: <FaHome/>,

  },
  {
    path: "/sensors",
    linkInHeader: true,
    type: "list",
    label: i18n.t("header.list.mysensors"),
    view: <SensorList/>,
    isHomeButton: true,
    icon: <MonitorWeight/>,

  },
  {
    path: "/patients",
    linkInHeader: true,
    type: "list",
    label: i18n.t("header.list.mypatients"),
    view: <BookingsList/>,
    isHomeButton: true,
    icon: <FaRegUser/>,
  },

];
