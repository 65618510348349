import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {get} from "../../utils/api";
import {RootState} from "store/store";
import {bookingListType} from "./types";
import {displayToastError, displayToastSuccess} from "features/ErrorBoundary/errorBoundarySlice";

export const fetchBookings = createAsyncThunk(
    "bookings/fetchBookings",
    async (page:number, {dispatch, rejectWithValue}) => {
      const response = await get(`home4/bookings?page=${page}&limit=5`);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("BOOKINGS.FETCH.FAILED"));
        return rejectWithValue("Cannot fetch bookings");
      } else {
        dispatch(displayToastSuccess("BOOKINGS.FETCH.SUCCESS"));
        return response;
      }
    },
);

export const deleteBooking= createAsyncThunk(
    "bookings/deleteBooking",
    async (booking_id:number, {rejectWithValue, dispatch}) => {
      const response = await get("home4/bookings/delete/"+booking_id);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("BOOKINGS.DELETE.FAILED"));
        return rejectWithValue("Cannot delete bookings");
      } else {
        dispatch(displayToastSuccess("BOOKINGS.DELETE.SUCCESS"));
        return response;
      }
    },
);


export interface apartmentListState {
    bookings:bookingListType[],
    loading: {
      fetchBookings:string,
      deleteBooking:string,
    },
    error: any,
  }

const initialState: apartmentListState = {
  bookings: [],
  loading: {
    fetchBookings: "idle",
    deleteBooking: "idle",
  },
  error: {},
};
export const bookingSlice = createSlice({
  name: "bookings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(fetchBookings.pending, (state, action) => {
          if (state.loading.fetchBookings === "idle") {
            state.loading.fetchBookings = "pending";
          }
        })
        .addCase(fetchBookings.fulfilled, (state, action) => {
          if (
            state.loading.fetchBookings === "pending"
          ) {
            state.loading.fetchBookings = "idle";
            state.bookings=action.payload.result;
          }
        })
        .addCase(fetchBookings.rejected, (state, action) => {
          if (
            state.loading.fetchBookings === "pending"
          ) {
            state.loading.fetchBookings = "idle";
            state.bookings=[];
            state.error = action.error;
          }
        })
        .addCase(deleteBooking.pending, (state, action) => {
          if (state.loading.deleteBooking === "idle") {
            state.loading.deleteBooking = "pending";
          }
        })
        .addCase(deleteBooking.fulfilled, (state, action) => {
          if (
            state.loading.deleteBooking === "pending"
          ) {
            state.loading.deleteBooking = "idle";
            state.bookings=state.bookings.filter((bookings)=>bookings.booking_id!==action.payload.result.deleted_id);
          }
        })
        .addCase(deleteBooking.rejected, (state, action) => {
          if (
            state.loading.deleteBooking === "pending"
          ) {
            state.loading.deleteBooking = "idle";
          }
        });
  },
});
export const selectBookings = (state: RootState) => state.bookingList.bookings;
export const selectLoadingBookingList = (state: RootState) => state.bookingList.loading;


export default bookingSlice.reducer;
